import { UseQueryOptions } from '@tanstack/react-query';
import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { gqlQueryClient } from '~/lib/backend';
import { queryClient } from '~/main';
import { customerSelector } from '~/querySelectors/customer';

const queryKey = (transactionId: string, userId: string) => [
  'customer',
  transactionId,
  userId
];
// Define select here to derive typing
const selector = (transactionId: string, userId: string) =>
  Selector('Query')({
    customer: [{ userId, transactionId }, customerSelector]
  });
export type CustomerQueryType = InputType<
  GraphQLTypes['Query'],
  ReturnType<typeof selector>
>;

/**
 * This query can be used in all react components. The reason why we store it
 * on the outermost level her (and not just the selector) is to ensure that we
 * reuse the query selector.
 *
 * @returns
 */
export default function customerQuery(
  transactionId?: string,
  userId?: string,
  dealershipSlug?: string
): UseQueryOptions<CustomerQueryType> {
  return {
    // Disable the query when transactionId or userId is not provided
    enabled: Boolean(transactionId && userId && dealershipSlug),
    queryKey: queryKey(transactionId ?? '', userId ?? ''),
    queryFn: () => {
      return gqlQueryClient({
        dealershipSlug: dealershipSlug ?? ''
      })(selector(transactionId ?? '', userId ?? ''));
    }
  };
}

export const resetCustomerQuery = (transactionId: string, userId: string) => {
  queryClient.resetQueries({
    queryKey: queryKey(transactionId, userId)
  });
};
