import {
  GraphQLTypes,
  InputType,
  OrderBy,
  Selector
} from '~/__generated__/backend/zeus';
import { addressSelector } from './address';
import { employmentSelector } from './employment';
import { hardCreditApplicationSelector } from './hardCreditApplication';
import { idCardSelector } from './idCard';

export const customerSelector = Selector('Customer')({
  userId: true,
  firstName: true,
  middleName: true,
  lastName: true,
  birthdate: true,
  socialSecurityNumber: true,

  onboardingStep: true,

  residentialAddresses: [
    {
      first: 5
    },
    {
      edges: {
        node: addressSelector
      }
    }
  ],
  employments: [
    {
      first: 20
    },
    {
      edges: {
        node: employmentSelector
      }
    }
  ],
  vehicleRegistrationAddress: addressSelector,
  wantsRegistrationAtSameAddress: true,
  hasInsurance: true,
  hasCoBuyer: true,
  hasTradeVehicle: true,
  hasPrequalApplication: true,
  wantsPayOff: true,
  wantsToPreQualify: true,
  currentMonthlyPayment: true,

  idCards: idCardSelector,

  testDriveDisclosure: {
    file: {
      id: true
    }
  },
  privacyPolicy: {
    id: true,
    formSubmissionData: true,
    file: {
      // Please don't retrieve URL here, as this that triggers an AWS call
      id: true
    },
    createdAt: true
  },
  contactAcknowledgement: {
    id: true,
    formSubmissionData: true,
    file: {
      // Please don't retrieve URL here, as this that triggers an AWS call
      id: true
    },
    createdAt: true
  },
  identityTheftPrevention: {
    id: true,
    formSubmissionData: true,
    file: {
      // Please don't retrieve URL here, as this that triggers an AWS call
      id: true
    },
    createdAt: true
  },
  hardCreditApplications: [
    {
      first: 1,
      orderBy: {
        createdAt: OrderBy.Desc
      }
    },
    {
      edges: {
        node: hardCreditApplicationSelector
      }
    }
  ],

  formSubmissions: [
    {},
    {
      edges: {
        node: {
          id: true,
          type: true
        }
      }
    }
  ]
});

export type CustomerType = InputType<
  GraphQLTypes['Customer'],
  typeof customerSelector
>;
