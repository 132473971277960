import { useQuery } from '@tanstack/react-query';
import { IdTheftPrevention } from '@thedealersconcierge/lib/codecs/schema/formSubmissionData';
import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import uploadFileAction from '~/actions/formSubmissions/uploadFileAction';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import DesktopWebcamModal from '~/components/DesktopWebcamModal';
import Header from '~/components/Header';
import CaptureIcon from '~/components/icons/CaptureIcon';
import LockIcon from '~/components/icons/LockIcon';
import Checkbox from '~/components/inputs/Checkbox';
import { gqlMutationClient } from '~/lib/backend';
import { identityTheftPreventionForm } from '~/lib/form/identity-theft-prevention';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

export default function IdentityTheftPreventionPage() {
  const mobileCameraInputRef = useRef<HTMLInputElement | null>(null);
  const { transactionId, dealershipSlug } = useParams(
    '/onboarding/:dealershipSlug/:transactionId/step2'
  );
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);
  const { data: meData } = useQuery(meQuery());

  const [capture, setCapture] = useState<File | undefined>(undefined);
  const [isDesktopWebcamModalOpen, setIsDesktopWebcamModalOpen] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!capture) {
      // proper error
      return;
    }
    try {
      setIsSubmitting(true);
      // We have to upload the profile image

      const captureIsPng = capture.type.includes('png');
      const captureUpload = await uploadFileAction(
        captureIsPng ? 'png' : 'jpg',
        captureIsPng ? 'image/png' : 'image/jpg',
        capture
      );
      const formSubmissionData: IdTheftPrevention = {
        submissionType: 'IDENTITY_THEFT_PREVENTION',
        submissionData: {
          image: URL.createObjectURL(capture), // This is used to render the image on the PDF
          dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
          name: `${meData?.me.user?.firstName ?? ''} ${meData?.me.user?.lastName ?? ''}`.trim(),
          ipAddress: meData?.me.ipAddress ?? 'Unknown IP',
          deviceId: window.navigator.userAgent
        }
      };

      // Generate identity theft prevention PDF
      const pdf = await generatePdf(
        identityTheftPreventionForm,
        formSubmissionData.submissionData,
        false
      );
      const pdfBlob = new Blob([pdf]);

      formSubmissionData.submissionData.image = `tdcfile://${captureUpload.fileId}`;

      // Upload identity verification PDF
      const pdfUpload = await uploadFileAction(
        'pdf',
        'application/pdf',
        pdfBlob
      );

      const resp = await gqlMutationClient()({
        onboardingSubmitStep2: [
          {
            transactionId,
            step2: {
              captureFileId: captureUpload.fileId,
              idTheftForm: JSON.stringify(formSubmissionData),
              idTheftPdfFileId: pdfUpload.fileId
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationOnboardingSubmitStep2Success': {
              data: { status: true }
            }
          }
        ]
      });

      if (
        resp.onboardingSubmitStep2.__typename ===
        'MutationOnboardingSubmitStep2Success'
      ) {
        navigate('/dashboard/:dealershipSlug/:transactionId', {
          params: { transactionId, dealershipSlug }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleLogout = async () => {
    await logoutAction();
  };
  const handleTakeSelfie = useCallback(() => {
    if (accepted) {
      if (isMobile) {
        mobileCameraInputRef.current?.click();
      } else {
        setIsDesktopWebcamModalOpen(true);
      }
    } else {
      window.alert(
        'You have to agree to the conditions first before taking a picture.'
      );
    }
  }, [accepted]);
  const handleMobileImageCapture = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setCapture(event.target.files[0]);
    }
  };
  const handleDesktopImageCapture = (file: File) => {
    setCapture(file);
    setIsDesktopWebcamModalOpen(false);
  };
  const handleCloseDesktopWebcamModal = () => {
    setIsDesktopWebcamModalOpen(false);
  };

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="Your Transaction Information"
        totalSteps={2}
        currentStep={2}
        leftElement={
          <button className=" text-primary-brand" onClick={handleLogout}>
            Cancel
          </button>
        }
      />

      {/**
       * This input opens the camera on mobile devices
       */}
      <input
        ref={mobileCameraInputRef}
        accept="image/*"
        type="file"
        capture="user"
        className="hidden"
        onChange={handleMobileImageCapture}
      />

      {/**
       * This is the webcam modal for desktop
       */}
      <DesktopWebcamModal
        title="Capture your face"
        isOpen={isDesktopWebcamModalOpen}
        orientation="PORTRAIT"
        onClose={handleCloseDesktopWebcamModal}
        onDone={handleDesktopImageCapture}
      />

      <div className="flex flex-col items-center overflow-y-scroll px-4">
        <div className="flex flex-col w-full max-w-screen-md py-20 md:py-30 lg:py-40 space-y-12 justify-between">
          <div className="space-y-6">
            <div className="space-y-5">
              <h1 className="text-heading-2">Identity Theft Prevention</h1>

              <div className="space-y-3">
                <div className="bg-secondary p-2 flex space-x-4 align-middle">
                  <div>
                    <LockIcon className="icon-interactive w-6" />
                  </div>
                  <p className="font-bold">
                    Your photo will be used to identify and detect fraud. The
                    image is securely stored.
                  </p>
                </div>
              </div>
            </div>

            <div
              className={classNames(
                'flex flex-row space-x-6 items-start cursor-pointer'
              )}
              onClick={() => setAccepted((a) => !a)}
            >
              <div className="flex mt-1">
                <Checkbox
                  inputId="acknowledged"
                  value={accepted}
                  onChange={() => setAccepted((a) => !a)}
                />
              </div>
              <div className="flex flex-col space-y-4">
                <p>
                  By submitting my picture, I agree that the Dealership may use
                  my provided photo/image only for lawful purposes, in this
                  case, to identify, process, and secure information to purchase
                  an automobile.
                </p>
                <p>
                  All information provided is my own and has been prepared based
                  on my representations alone. No one else has assisted me in
                  the information provided and may be used in my credit
                  application if I choose to do so.
                </p>
              </div>
            </div>

            {!capture && (
              <div
                className="flex flex-col space-y-4 items-center cursor-pointer"
                onClick={handleTakeSelfie}
              >
                <h3>Please take a selfie</h3>

                <CaptureIcon className="max-w-64 icon-tertiary" />
              </div>
            )}

            {capture && (
              <div className="relative flex justify-center">
                <img
                  src={
                    URL.createObjectURL(capture!) // We can force unwrap because if there's no existing profile picture, there's a capture
                  }
                  className="w-1/2 aspect-square object-contain"
                />
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              disabled={!capture}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
