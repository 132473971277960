import { useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import Header from '~/components/Header';
import Spinner from '~/components/Spinner';
import FileErrorIcon from '~/components/icons/FileErrorIcon';
import { gqlQueryClient } from '~/lib/backend';
import { getHumanReadableFormAndDocumentType } from '~/lib/files';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

export default function GloveCompartmentDocumentPage() {
  // We need to use refs to determine the width for the PDFs as the classNames are not respected for the width
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { transactionId, documentId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/gloveCompartment/document/:documentId'
  );
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(0);
  const handleClose = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId/gloveCompartment', {
      params: { transactionId, dealershipSlug }
    });
  };
  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const { data: userData, isLoading: loadingUserData } = useQuery(meQuery());

  const { data: formSubmissionsData, isLoading: loadingFormSubmissions } =
    useQuery({
      refetchOnReconnect: false,
      refetchInterval: false,
      enabled: Boolean(userData?.me.user.id),
      queryKey: [
        'transaction',
        transactionId,
        'customer',
        'documents',
        documentId,
        dealershipSlug
      ],
      queryFn: async () =>
        gqlQueryClient({ dealershipSlug })({
          customer: [
            {
              userId: userData?.me.user.id ?? '', // For typing, the query is only enabled when this is set
              transactionId
            },
            {
              documents: [
                {
                  filter: {
                    id: {
                      equals: documentId
                    }
                  }
                },
                {
                  edges: {
                    node: {
                      id: true,
                      category: true,
                      file: {
                        url: true
                      }
                    }
                  }
                }
              ]
            }
          ]
        })
    });
  const formSubmission = formSubmissionsData?.customer.documents.edges.at(0);

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={getHumanReadableFormAndDocumentType(
          formSubmission?.node.category ?? 'Document'
        )}
        leftElement={
          <button className=" text-primary-brand" onClick={handleClose}>
            Close
          </button>
        }
      />

      {(loadingUserData || loadingFormSubmissions) && (
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      )}

      {!loadingUserData && !loadingFormSubmissions && (
        <div className="flex flex-col p-8 items-center">
          <div
            ref={containerRef}
            className="flex flex-col w-full max-w-full md:max-w-screen-md"
          >
            <Document
              file={formSubmission?.node.file?.url}
              onLoadSuccess={handleDocumentLoadSuccess}
              className="flex flex-col space-y-1"
              loading={
                <div className="flex w-full min-h-[80vh] justify-center items-center">
                  <Spinner />
                </div>
              }
              error={
                <div className="flex flex-col w-full min-h-[80vh] justify-center items-center">
                  <div className="flex flex-col space-y-4 items-center">
                    <div className="relative">
                      <FileErrorIcon className="w-20 icon-negative" />
                    </div>

                    <p className="text-negative">Failed to load document</p>
                  </div>
                </div>
              }
            >
              {[...new Array(numPages)].map((_, index) => (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  className="border"
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  width={containerRef.current?.clientWidth}
                />
              ))}
            </Document>
          </div>
        </div>
      )}
    </div>
  );
}
