import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { useAtomValue } from 'jotai';
import { useLocation } from 'react-router-dom';
import { OnboardingStep, Selector } from '~/__generated__/backend/zeus';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import { gqlQueryClient } from '~/lib/backend';
import { useNavigate } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';
import ConsumerDashboardHeader from './[dealershipSlug]/[transactionId]/_components/ConsumerDashboardHeader';
import { navigateToTransaction } from './_utils';

const transactionSelector = Selector('Transaction')({
  id: true,
  buyerId: true,
  createdAt: true,
  customerSharedData: {
    buyerOnboardingStep: true,
    coBuyerOnboardingStep: true
  },
  dealership: {
    name: true,
    slug: true
  },
  vehicle: {
    make: true,
    model: true
  }
});

export default function TransactionOverviewPage() {
  const location = useLocation();
  const doRedirect = location?.state?.doRedirect;
  const redirectTo = location?.state?.redirectTo;
  const dealerKiosk = useAtomValue(kioskDealershipAtom);

  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = async () => {
    setLoggingOut(true);
    await logoutAction();
  };

  const navigate = useNavigate();

  const { data: meData } = useQuery({
    queryKey: ['transactionOverview', dealerKiosk?.dealershipSlug],
    queryFn: async () =>
      gqlQueryClient()({
        me: {
          user: {
            id: true,
            buyerFor: [
              {
                filter: {
                  dealership: dealerKiosk?.dealershipSlug
                    ? {
                        slug: { equals: dealerKiosk.dealershipSlug }
                      }
                    : undefined
                }
              },
              {
                edges: {
                  node: transactionSelector
                }
              }
            ],
            coBuyerFor: [
              {
                filter: {
                  dealership: dealerKiosk?.dealershipSlug
                    ? {
                        slug: { equals: dealerKiosk.dealershipSlug }
                      }
                    : undefined
                }
              },
              {
                edges: {
                  node: transactionSelector
                }
              }
            ]
          }
        }
      })
  });

  const buyerFor = meData?.me.user.buyerFor.edges;
  const coBuyerFor = meData?.me.user.coBuyerFor.edges;

  const allTransactions =
    (
      buyerFor?.map((t) => ({
        role: 'BUYER',
        ...t.node
      })) ?? []
    ).concat(
      coBuyerFor?.map((t) => ({
        role: 'CO_BUYER',
        ...t.node
      })) ?? []
    ) ?? [];

  // Generally, don't use useEffect.
  // Here we setup a special case where we allow redirecting when there is
  // only a single transaction in the list. This is to make the login flow
  // a bit more consolidated
  useEffect(() => {
    // Explicit direct to an ID
    if (redirectTo) {
      const transCoBuyer = coBuyerFor?.find((t) => t.node.id === redirectTo);
      const transBuyer = buyerFor?.find((t) => t.node.id === redirectTo);
      // Assume that the user is not both buyer and co-buyer (Which I find reasonable)
      if (transCoBuyer || transBuyer) {
        const step =
          transCoBuyer?.node.customerSharedData.coBuyerOnboardingStep ??
          transBuyer?.node.customerSharedData.buyerOnboardingStep;
        const slug = (transCoBuyer ?? transBuyer)?.node.dealership?.slug;

        navigateToTransaction(redirectTo, slug ?? 'slug', navigate, step);
      }
    }

    // Redirect when there is only a single transaction
    if (doRedirect && allTransactions.length === 1) {
      if (coBuyerFor?.at(0)) {
        const transactionId = coBuyerFor?.at(0)?.node.id ?? 'never';

        const step =
          coBuyerFor?.at(0)?.node.customerSharedData.coBuyerOnboardingStep;
        const slug = coBuyerFor?.at(0)?.node.dealership?.slug;

        navigateToTransaction(transactionId, slug ?? 'slug', navigate, step);
      } else if (buyerFor?.at(0)) {
        const transactionId = buyerFor?.at(0)?.node.id ?? 'never';

        const step =
          buyerFor?.at(0)?.node.customerSharedData.buyerOnboardingStep;
        const slug = buyerFor?.at(0)?.node.dealership?.slug;

        navigateToTransaction(transactionId, slug ?? 'slug', navigate, step);
      }
    }
  }, [doRedirect, redirectTo, allTransactions.length]);

  return (
    <div>
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={<h3>Transactions</h3>}
        rightElement={
          <Button
            variant="TEXT_ONLY"
            onClick={handleLogout}
            loading={loggingOut}
            className="!py-0 pr-0 h-fit"
          >
            <span className="text-secondary">Logout</span>
          </Button>
        }
      />

      <div className="flex justify-center bg-secondary py-4 px-6">
        <div className="bg-primary w-full max-w-screen-lg p-4 rounded-lg shadow-border space-y-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row space-x-2">
              <h2>Transaction Overview</h2>
            </div>
          </div>

          <ul className="flex flex-col space-y-2">
            <li className="flex justify-between rounded-lg shadow-border bg-secondary px-4 py-2">
              <div className="w-1/3 font-bold">Transaction</div>
              <div className="font-bold">Dealership</div>
              <div className="font-bold">Role</div>
            </li>
            {allTransactions.map((t) => {
              const date = format(t.createdAt, 'MM/dd/yyyy');
              const vehicleName = t.vehicle
                ? ` | ${t.vehicle.make} ${t.vehicle.model}`
                : '';
              const transactionId = t.id;

              return (
                <li
                  key={transactionId}
                  onClick={() =>
                    navigateToTransaction(
                      transactionId,
                      t.dealership?.slug ?? 'slug',
                      navigate,
                      OnboardingStep.STEP1
                      // TODO
                      // meData?.me.user.id === t?.buyerId
                      //   ? t?.step
                      //   : t?.stepCoBuyer
                    )
                  }
                  className="cursor-pointer flex justify-between rounded-lg shadow-border bg-blue-50 px-4 py-2 hover:bg-blue-100"
                >
                  <div className="w-1/3">
                    {date}
                    {vehicleName}
                  </div>
                  <div>{t.dealership?.name ?? ''}</div>
                  <div>{t.role === 'BUYER' ? 'Buyer' : 'Co-Buyer'}</div>
                </li>
              );
            })}
          </ul>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="grid md:grid-cols-2 md:col-span-2 gap-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
