import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import dealershipQuery from '~/queries/dealershipQuery';
import { Link } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';

export default function IndexRoute() {
  const kioskMode = useAtomValue(kioskDealershipAtom);
  const { data: dealership } = useQuery(
    dealershipQuery({ slug: kioskMode?.dealershipSlug })
  );

  return (
    <div
      className={classNames(
        'flex flex-col items-center w-screen space-y-12 p-10',
        'sm:p-12',
        'md:p-14',
        'lg:p-16'
      )}
    >
      <img
        src={
          dealership?.dealership.logoUrl
            ? dealership.dealership.logoUrl
            : '/logo.png'
        }
        className={classNames(
          'h-16 object-contain',
          'sm:h-20',
          'md:h-24',
          'lg:h-28',
          'xl:h-32',
          '2xl:h-36'
        )}
      />

      {kioskMode && (
        <h1 className="text-center">
          Please select what type of user you are.
        </h1>
      )}

      <div className="w-full max-w-lg flex flex-col space-y-8">
        {kioskMode && (
          <Link
            data-test-id="kiosk-new-user"
            to="/signup"
            className="bg-interactive-primary text-primary-inverse flex flex-col items-center space-y-4 shadow-lg p-12 rounded-xl"
          >
            <h2>New TDC User</h2>

            <p className="text-center">
              Create an account and a new transaction at{' '}
              <b>{dealership?.dealership.name}</b>
            </p>
          </Link>
        )}

        <Link
          to="/signin"
          data-test-id="consumer-sign-in-button"
          className="bg-interactive-tertiary flex flex-col items-center space-y-4 shadow-lg p-12 rounded-xl"
        >
          <h2>Existing TDC User</h2>

          <p className="text-center">
            Create a new transaction with your existing credentials
          </p>
        </Link>
      </div>
    </div>
  );
}
