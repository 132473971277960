import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';
import { employmentSelector } from '~/querySelectors/employment';

const createEmploymentAction = async (
  transactionId: string,
  values: GraphQLTypes['CreateEmploymentInput']
) => {
  const resp = await gqlMutationClient()({
    createEmployment: [
      {
        transactionId,
        employment: values
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationCreateEmploymentSuccess': {
          data: employmentSelector
        }
      }
    ]
  });

  if (resp.createEmployment.__typename === 'GraphQLError') {
    throw new Error(resp.createEmployment.message);
  }
};

export default createEmploymentAction;
