import { ChangeEvent, FC, Fragment } from 'react';
import { convertImageToPDF } from '~/lib/pdf';
import Spinner from '../Spinner';
import FileInputThumbnail from './FileInputThumbnail';

type FileInputProps = {
  setSelectedFiles: (files: File[]) => void;
  selectedFiles: File[];
  uploading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
}

const FileInput: FC<FileInputProps> = ({
  uploading = false,
  disabled = false,
  multiple = false,
  setSelectedFiles,
  selectedFiles=[],
}) => {
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      const existingFilesMap = new Map<string, File>();
      selectedFiles.forEach(file => existingFilesMap.set(file.name, file));
  
      const updatedFiles = [...selectedFiles];
  
      // If the file of type image then convert it
      // Else it will do no thing
      for (const file of newFiles) {
        if (!existingFilesMap.has(file.name)) {
          if (file.type.startsWith('image/')) {
            const pdfFile = await convertImageToPDF(file, );
            updatedFiles.push(pdfFile);
          } else {
            updatedFiles.push(file);
          }
        }
      }
  
      setSelectedFiles(updatedFiles);
    }
  };  

  const handleDelete = (fileName: string) => {
    const filteredFiles = selectedFiles.filter(file => file.name != fileName);
    setSelectedFiles([...filteredFiles]);
  };

  return (
    <div className="bg-white rounded w-full mx-auto">
      <div className="relative flex flex-col items-center p-4 text-tertiary border-2 border-inactive hover:text-secondary hover:border-primary border-dashed rounded">
        {uploading && (
            <div className='flex flex-row gap-4 items-center'>
              <Spinner className="w-12 aspect-square min-h-40"/>
            </div>
        )}
      
        {!uploading && (
          <Fragment>
            <input
          accept="image/*, application/pdf"
          type="file"
          multiple={multiple}
          className="absolute inset-0 z-2 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
          onChange={handleChange}
          disabled={uploading || disabled}
        />
        {selectedFiles.length > 0 ? (
            <div className="flex flex-wrap justify-center gap-4 p-4">
              {selectedFiles.map((file, i) => (
                <FileInputThumbnail 
                  key={i}
                  file={file}
                  onDelete={() => handleDelete(file.name)}
                />
              ))}
            </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-10 text-center">
                <p className="m-0">Drag your files here or click in this area.</p>
              </div>
            )}
          </Fragment>
        )}
        
      </div>
    </div>
  );
};

export default FileInput;
