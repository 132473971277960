import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';
import { AddressType, addressSelector } from '~/querySelectors/address';

const createAddressAction = async (
  values: GraphQLTypes['AddressInput'],
  transactionId?: string
): Promise<AddressType> => {
  const resp = await gqlMutationClient()({
    createAddress: [
      {
        transactionId,
        address: values
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationCreateAddressSuccess': {
          data: addressSelector
        }
      }
    ]
  });

  if (resp.createAddress.__typename === 'GraphQLError') {
    throw new Error(resp.createAddress.message);
  }

  return resp.createAddress.data;
};

export default createAddressAction;
