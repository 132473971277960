import { useForm } from '@tanstack/react-form';
import classNames from 'classnames';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import Button from '~/components/Button';
import DateInput from '~/components/inputs/DateInput';
import DropDown from '~/components/inputs/DropDown';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import SocialSecurityNumberInput from '~/components/inputs/SocialSecurityNumberInput';
import TextInput from '~/components/inputs/TextInput';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { maskSSNRegex } from '~/lib/stringUtils';
import { getDateFromUnkown } from '~/lib/utils';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';

const BasicInformationForm: FC<{
  hardCreditApplication: HardCreditApplicationType;
  // We have the onSave function has a prop because this only updates the local state of the updated hard credit application in the parent component
  onSave: (values: {
    firstName: string;
    lastName: string;
    middleName: string;
    birthdate: string;
    socialSecurityNumber: string;
    driversLicenseNumber: string;
    driversLicenseState: string;
    homePhoneNumber: string;
    phoneNumber: string;
    email: string;
  }) => void;
}> = ({ hardCreditApplication, onSave }) => {
  const [editing, setEditing] = useState(false);
  const handleEdit = () => {
    setEditing(true);
  };
  const form = useForm({
    defaultValues: {
      firstName: hardCreditApplication.firstName ?? '',
      lastName: hardCreditApplication?.lastName ?? '',
      middleName: hardCreditApplication?.middleName ?? '',
      birthdate:
        getDateFromUnkown(hardCreditApplication.birthdate)?.toISOString() ?? '',
      socialSecurityNumber: hardCreditApplication?.socialSecurityNumber ?? '',
      driversLicenseNumber: hardCreditApplication?.driversLicenseNumber ?? '',
      driversLicenseState: hardCreditApplication?.driversLicenseState ?? '',
      homePhoneNumber: hardCreditApplication?.homePhoneNumber ?? '',
      phoneNumber: hardCreditApplication?.phoneNumber ?? '',
      email: hardCreditApplication?.email ?? ''
    },
    onSubmit: (values) => {
      onSave(values);
      setEditing(false);
    }
  });
  const handleDiscard = () => {
    setEditing(false);
    form.reset();
  };
  const formatBirthdate = (birthdate?: unknown) => {
    const date = getDateFromUnkown(birthdate);

    if (!date) {
      return '-';
    }

    return format(date, 'yyyy-MM-dd');
  };

  return (
    <div
      className={classNames('flex flex-col items-center w-full space-y-4', {
        'px-6': !editing
      })}
    >
      {!editing && (
        <div className="flex flex-col space-y-6 p-4 rounded-lg shadow-border w-full max-w-screen-md px-6">
          <div className="flex flex-row w-full justify-between items-center">
            <h3>Basic Information</h3>

            <Button
              size="SMALL"
              variant="TEXT_ONLY"
              onClick={() => handleEdit()}
            >
              Edit
            </Button>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">First Name</p>

              <p className="text-secondary">
                {hardCreditApplication.firstName}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Middle Name</p>

              <p className="text-secondary">
                {hardCreditApplication.middleName?.length
                  ? hardCreditApplication.middleName
                  : '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Last Name</p>

              <p className="text-secondary">{hardCreditApplication.lastName}</p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Birthdate</p>

              <p className="text-secondary">
                {formatBirthdate(hardCreditApplication.birthdate)}
              </p>
            </div>

            <div className="space-y-2 col-span-2 md:col-span-1">
              <p className="text-body-2 text-tertiary">
                Social Security Number
              </p>

              <p className="text-secondary">
                {hardCreditApplication.socialSecurityNumber
                  ? maskSSNRegex(hardCreditApplication.socialSecurityNumber)
                  : '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">
                Driver's License Number
              </p>

              <p className="text-secondary">
                {hardCreditApplication.driversLicenseNumber ?? '-'}
              </p>
            </div>

            <div className="space-y-2 md:col-span-2">
              <p className="text-body-2 text-tertiary">
                Driver's License State
              </p>

              <p className="text-secondary">
                {stateOptions.find(
                  (o) => o.value === hardCreditApplication.driversLicenseState
                )?.label ??
                  hardCreditApplication.driversLicenseState ??
                  '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Home Phone Number</p>

              <p className="text-secondary">
                {hardCreditApplication.homePhoneNumber ?? '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Phone Number</p>

              <p className="text-secondary">
                {hardCreditApplication.phoneNumber ?? '-'}
              </p>
            </div>
          </div>
        </div>
      )}

      {editing && (
        <form.Provider>
          <div className="flex w-full justify-center bg-secondary border-y border-secondary p-6">
            <form
              className="flex flex-col space-y-6 w-full max-w-screen-md"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <div className="flex flex-row w-full justify-between items-center">
                <h3>Basic Information</h3>

                <div className="flex flex-row items-center">
                  <form.Subscribe selector={(state) => [state.values]}>
                    {([values]) => {
                      const canContinue =
                        !!values.firstName &&
                        !!values.lastName &&
                        !!values.birthdate &&
                        !!values.socialSecurityNumber &&
                        !!values.driversLicenseNumber &&
                        !!values.driversLicenseState &&
                        !!values.phoneNumber;

                      return (
                        <Button
                          type="submit"
                          size="SMALL"
                          variant="TEXT_ONLY"
                          disabled={!canContinue}
                        >
                          Save
                        </Button>
                      );
                    }}
                  </form.Subscribe>

                  <span className="icon-tertiary text-sm">|</span>

                  <Button
                    size="SMALL"
                    variant="TEXT_ONLY"
                    onClick={() => handleDiscard()}
                  >
                    Discard
                  </Button>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-8 gap-4 items-end w-full">
                <form.Field name="firstName">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="First Name"
                        placeholder="First Name"
                        subtitleText="First Name"
                        required
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-3"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="middleName">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Middle Name"
                        subtitleText="Middle Name"
                        placeholder="Middle Name"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-2"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="lastName">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Last Name"
                        placeholder="Last Name"
                        subtitleText="Last Name"
                        required
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-3"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="birthdate">
                  {(field) => {
                    return (
                      <DateInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Birthdate"
                        subtitleText="Your Date of Birth"
                        placeholder="Birthdate"
                        required
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="socialSecurityNumber">
                  {(field) => {
                    return (
                      <SocialSecurityNumberInput
                        value={field.state.value}
                        fieldName={field.name}
                        subtitleText="Social Security Number"
                        placeholder="Social Security Number"
                        required
                        onBlur={field.handleBlur}
                        onChange={(e) => field.handleChange(e.target.value)}
                        setFieldValue={(_fieldName, value) =>
                          form.setFieldValue(field.name, value)
                        }
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="driversLicenseNumber">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Driver's License Number"
                        subtitleText="Driver's License Number"
                        placeholder="Driver's License Number"
                        required
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="driversLicenseState">
                  {(field) => {
                    return (
                      <DropDown
                        fieldName={field.name}
                        value={field.state.value}
                        options={stateOptions}
                        labelText="Driver's State"
                        subtitleText="Driver's State"
                        placeholder="Driver's State"
                        required
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="homePhoneNumber">
                  {(field) => {
                    return (
                      <PhoneNumberInput
                        fieldName={field.name}
                        value={field.state.value}
                        subtitleText="Home Phone Number"
                        placeholder="Home Phone Number"
                        onChange={(value) =>
                          field.handleChange(value?.toString() ?? '')
                        }
                        onBlur={field.handleBlur}
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="phoneNumber">
                  {(field) => {
                    return (
                      <PhoneNumberInput
                        fieldName={field.name}
                        value={field.state.value}
                        placeholder="Phone Number"
                        subtitleText="Phone Number"
                        onChange={(value) =>
                          field.handleChange(value?.toString() ?? '')
                        }
                        onBlur={field.handleBlur}
                        required
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>
              </div>
            </form>
          </div>
        </form.Provider>
      )}
    </div>
  );
};

export default BasicInformationForm;
