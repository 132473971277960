import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import logoutAtion from '~/actions/logoutAction';
import meQuery from '~/queries/meQuery';
import { useNavigate } from '~/router';
import { authStateAtom } from '~/state/auth';

/**
 * This hooks ensures that the user is logged in. If not, it will navigate
 * the use to the login page
 *
 * Use this components on pages that need to be protected by login (this
 * is being used in the layout components for onboarding and dashboard)
 *
 * @returns
 */
export default function AuthCheckerHook() {
  const navigate = useNavigate();
  const authState = useAtomValue(authStateAtom);
  const { data: meData, isLoading } = useQuery(meQuery());
  const [delayed, setDelayed] = useState(false);

  // This is a major hack. The problem is, that the atoms are not loading
  // properly as they are not async. Feel free to remove this, when that is
  // fixed.
  useEffect(() => {
    setTimeout(() => setDelayed(true), 100);
  }, []);

  useEffect(() => {
    if (!delayed) {
      return;
    }
    if (!authState) {
      navigate('/');
      return;
    }

    if (!isLoading && !meData) {
      // No data for the user is present, so the user does not exist
      logoutAtion();
    }
  }, [authState, isLoading, meData, delayed]);

  return null;
}
