import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';

type Fields = 'name' | 'dateTime' | 'deviceId' | 'ipAddress';
export const vehicleDiscolureTemplate: DocumentTemplate<Fields> = {
  fileUrl: '/docs/test_drive_disclosure.pdf',
  fields: {
    ...legalStamps('form-test-drive-disclosure', [0, 1])
  }
};
