import { FormApi, useForm } from '@tanstack/react-form';
import { FC, useState } from 'react';
import createAddressAction from '~/actions/addresses/createAddressAction';
import deleteAddressAction from '~/actions/addresses/deleteAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import Button from '~/components/Button';
import DropDown from '~/components/inputs/DropDown';
import NumberInput, {
  parseNumberInputValue
} from '~/components/inputs/NumberInput';
import TextInput from '~/components/inputs/TextInput';
import housingStatusOptions from '~/config/formSelectionOptions/housingStatusOptions';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { resetHardCreditQuery } from '~/queries/hardCreditQuery';
import { resetMeQuery } from '~/queries/meQuery';
import { AddressType } from '~/querySelectors/address';

const AddressFormFields: FC<{
  form: FormApi<
    {
      street: string;
      apartmentDetails: string;
      zipCode: string;
      city: string;
      state: string;
      housingStatus: string;
      monthlyPayment: string;
      durationYears: string;
      durationMonths: string;
    },
    unknown
  >;
  isSubmitting: boolean;
  isDeleting: boolean;
}> = ({ form, isSubmitting, isDeleting }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-8 gap-4 w-full max-w-screen-md justify-end">
      <form.Field name="street">
        {(field) => {
          return (
            <TextInput
              fieldName={field.name}
              value={field.state.value}
              labelText="Street"
              subtitleText="Street"
              placeholder="Street"
              required
              disabled={isSubmitting || isDeleting}
              error={field.state.meta.touchedErrors.at(0)}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="md:col-span-4"
            />
          );
        }}
      </form.Field>

      <form.Field name="apartmentDetails">
        {(field) => {
          return (
            <TextInput
              fieldName={field.name}
              value={field.state.value}
              labelText="Apartment Details"
              subtitleText="Apartment Details"
              placeholder="Suite, Apt #"
              disabled={isSubmitting || isDeleting}
              error={field.state.meta.touchedErrors.at(0)}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="md:col-span-4"
            />
          );
        }}
      </form.Field>

      <form.Field name="zipCode">
        {(field) => {
          return (
            <TextInput
              fieldName={field.name}
              value={field.state.value}
              labelText="Zip Code"
              subtitleText="Zip Code"
              placeholder="Zip Code"
              required
              disabled={isSubmitting || isDeleting}
              error={field.state.meta.touchedErrors.at(0)}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="md:col-span-3"
            />
          );
        }}
      </form.Field>

      <form.Field name="city">
        {(field) => {
          return (
            <TextInput
              fieldName={field.name}
              value={field.state.value}
              labelText="City"
              subtitleText="City"
              placeholder="City"
              required
              disabled={isSubmitting || isDeleting}
              error={field.state.meta.touchedErrors.at(0)}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="md:col-span-2"
            />
          );
        }}
      </form.Field>

      <form.Field name="state">
        {(field) => {
          return (
            <DropDown
              fieldName={field.name}
              value={field.state.value}
              options={stateOptions}
              labelText="State"
              placeholder="State"
              subtitleText="State"
              required
              disabled={isSubmitting || isDeleting}
              error={field.state.meta.touchedErrors.at(0)}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="md:col-span-3"
            />
          );
        }}
      </form.Field>

      <form.Field name="housingStatus">
        {(field) => {
          return (
            <DropDown
              fieldName={field.name}
              value={field.state.value}
              options={housingStatusOptions}
              labelText="Housing Status"
              subtitleText="Housing Status"
              placeholder="Housing Status"
              required
              disabled={isSubmitting || isDeleting}
              error={field.state.meta.touchedErrors.at(0)}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="md:col-span-4"
            />
          );
        }}
      </form.Field>

      <form.Field name="monthlyPayment">
        {(field) => {
          return (
            <NumberInput
              fieldName={field.name}
              value={field.state.value}
              subtitleText="Monthly Payment"
              prefix="$"
              suffix="/month"
              placeholder="/month"
              required
              disabled={isSubmitting || isDeleting}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="col-span-2 md:col-span-4"
            />
          );
        }}
      </form.Field>

      <form.Field name="durationYears">
        {(field) => {
          return (
            <NumberInput
              fieldName={field.name}
              value={field.state.value}
              labelText="Duration in Years"
              subtitleText="How many years have you been living at this address?"
              placeholder="Duration (Yrs.)"
              disabled={isSubmitting || isDeleting}
              required
              suffix=" Yrs."
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="md:col-span-4"
            />
          );
        }}
      </form.Field>

      <form.Field name="durationMonths">
        {(field) => {
          return (
            <NumberInput
              fieldName={field.name}
              value={field.state.value}
              labelText="Duration in Months"
              subtitleText="How many months have you been living at this address?"
              placeholder="Duration (Mos.)"
              suffix=" Mos."
              disabled={isSubmitting || isDeleting}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="md:col-span-4"
            />
          );
        }}
      </form.Field>
    </div>
  );
};

const AddressForm: FC<{
  transactionId: string;
  addresses: AddressType[];
  hasMoreThanTwoYearsHistory: boolean;
}> = ({ transactionId, addresses, hasMoreThanTwoYearsHistory }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editingPos, setEditingPos] = useState<number | undefined>(undefined);
  const handleEdit = (pos?: number) => {
    setIsAdding(false);
    setEditingPos(pos);
  };
  const handleDiscard = () => {
    setIsAdding(false);
    setEditingPos(undefined);
  };
  const handleAdd = () => {
    setEditingPos(undefined);
    setIsAdding(true);
  };
  const editedAddress = addresses.find(
    (a) => a.timelinePosition === editingPos
  );

  const form = useForm({
    defaultValues: {
      street: editedAddress?.street ?? '',
      apartmentDetails: editedAddress?.apartmentDetails ?? '',
      zipCode: editedAddress?.zipCode ?? '',
      city: editedAddress?.city ?? '',
      state: editedAddress?.state ?? '',
      housingStatus: editedAddress?.housingStatus ?? '',
      monthlyPayment: editedAddress?.monthlyPayment?.toString() ?? '',
      durationYears: editedAddress?.durationYears?.toString() ?? '',
      durationMonths: editedAddress?.durationMonths?.toString() ?? ''
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);

      // Check if we are editing an existing address
      if (editedAddress) {
        await updateAddressAction(editedAddress.id, {
          ...values,
          monthlyPayment: parseNumberInputValue(values['monthlyPayment']),
          durationYears: parseInt(values['durationYears']),
          durationMonths: values['durationMonths']
            ? parseInt(values['durationMonths'])
            : 0
        });

        await Promise.all([
          await resetMeQuery(),
          await resetHardCreditQuery(transactionId)
        ]);

        setEditingPos(undefined);
        form.reset();
      } else {
        // We are creating a new address
        await createAddressAction(
          {
            ...values,
            monthlyPayment: parseNumberInputValue(values['monthlyPayment']),
            durationYears: parseNumberInputValue(values['durationYears']),
            durationMonths:
              parseNumberInputValue(values['durationMonths']) ?? 0,
            timelinePosition: addresses.length
          },
          transactionId
        );

        await Promise.all([
          await resetMeQuery(),
          await resetHardCreditQuery(transactionId)
        ]);

        setEditingPos(undefined);
        setIsAdding(false);
        form.reset();
      }

      setIsSubmitting(false);
    }
  });

  const handleDelete = async (addressId: string) => {
    setIsDeleting(true);
    await deleteAddressAction(addressId);
    await resetMeQuery();
    resetHardCreditQuery(transactionId);
    setEditingPos(undefined);
    form.reset();
    setIsDeleting(false);
  };

  return (
    <div className="flex flex-col items-center w-full space-y-4">
      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
          <h1 className="w-full max-w-screen-md">Address Information</h1>

          {!isAdding && !hasMoreThanTwoYearsHistory && (
            <Button size="SMALL" variant="TEXT_ONLY" onClick={handleAdd}>
              +Add
            </Button>
          )}
        </div>
      </div>

      {addresses.map((a) => {
        if (editingPos === a.timelinePosition) {
          return (
            <form.Provider key={a.id}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  void form.handleSubmit();
                }}
                className="flex flex-col w-full space-y-4 bg-secondary border-y border-secondary items-center p-6"
              >
                <div className="flex flex-row justify-between w-full max-w-screen-md items-center">
                  <h3>
                    {a.timelinePosition === 0 &&
                      'Address Information and Housing Status'}
                    {a.timelinePosition === 1 &&
                      'Previous Address Information and Housing Status'}
                    {a.timelinePosition === 2 &&
                      '2nd Previous Address Information and Housing Status'}
                    {a.timelinePosition === 3 &&
                      '3rd Previous Address Information and Housing Status'}
                    {!!a.timelinePosition &&
                      a.timelinePosition > 3 &&
                      `${a.timelinePosition}th Previous Address Information and Housing Status`}
                  </h3>

                  <div className="flex flex-row items-center">
                    <form.Subscribe
                      selector={(state) => [state.values]}
                      children={([values]) => {
                        const canContinue =
                          !!values.street &&
                          !!values.zipCode &&
                          !!values.city &&
                          !!values.state &&
                          !!values.housingStatus &&
                          !!values.monthlyPayment &&
                          values.durationYears !== '' &&
                          (parseInt(values.durationYears.toString()) > 0 ||
                            parseInt(values.durationMonths.toString()) > 0);

                        return (
                          <Button
                            disabled={!canContinue || isDeleting}
                            loading={isSubmitting}
                            type="submit"
                            size="SMALL"
                            variant="TEXT_ONLY"
                          >
                            Save
                          </Button>
                        );
                      }}
                    />

                    <span className="icon-tertiary text-sm">|</span>

                    <Button
                      size="SMALL"
                      variant="TEXT_ONLY"
                      onClick={() => handleDiscard()}
                    >
                      Discard
                    </Button>
                  </div>
                </div>

                <AddressFormFields
                  form={form}
                  isSubmitting={isSubmitting}
                  isDeleting={isDeleting}
                />
              </form>
            </form.Provider>
          );
        }

        return (
          <div key={a.id} className="flex w-full justify-center px-6">
            <div className="flex flex-col space-y-6 p-4 rounded-lg shadow-border w-full max-w-screen-md px-6">
              <div className="flex flex-row w-full justify-between items-center">
                {a.timelinePosition === 0 && (
                  <h3>Current Address and Housing Status</h3>
                )}
                {a.timelinePosition === 1 && (
                  <h3>Previous Address and Housing Status</h3>
                )}
                {a.timelinePosition === 2 && (
                  <h3>2nd Previous Address and Housing Status</h3>
                )}
                {a.timelinePosition === 3 && (
                  <h3>3rd Previous Address and Housing Status</h3>
                )}
                {!!a.timelinePosition && a.timelinePosition > 3 && (
                  <h3>
                    ${a.timelinePosition}th Previous Address and Housing Status
                  </h3>
                )}

                <div className="flex flex-row items-center">
                  <Button
                    size="SMALL"
                    variant="TEXT_ONLY"
                    onClick={() => handleEdit(a.timelinePosition)}
                  >
                    Edit
                  </Button>

                  <span className="icon-tertiary text-sm">|</span>

                  <Button
                    size="SMALL"
                    variant="TEXT_ONLY"
                    onClick={() => handleDelete(a.id)}
                    loading={isDeleting}
                    disabled={addresses.length < 2}
                  >
                    Delete
                  </Button>
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div className="space-y-2 col-span-2 md:col-span-1">
                  <p className="text-body-2 text-tertiary">Address:</p>

                  <p className="text-secondary">{a.fullAddress}</p>
                </div>

                <div className="space-y-2 ">
                  <p className="text-body-2 text-tertiary">Time at Address:</p>

                  <p className="text-secondary">
                    {a.durationYears} Yrs {a.durationMonths} Mos
                  </p>
                </div>

                <div className="space-y-2">
                  <p className="text-body-2 text-tertiary">Housing Status:</p>

                  <p className="text-secondary">
                    {
                      housingStatusOptions.find(
                        (o) => o.value === a.housingStatus
                      )?.label
                    }
                  </p>
                </div>

                <div className="space-y-2">
                  <p className="text-body-2 text-tertiary">Monthly Payment:</p>

                  <p className="text-secondary">
                    ${a.monthlyPayment?.toLocaleString()}/month
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {isAdding && !editedAddress && (
        <form.Provider>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
            className="flex flex-col w-full space-y-4 bg-secondary border-y border-secondary items-center p-6"
          >
            <div className="flex flex-row justify-between w-full max-w-screen-md items-center">
              <h3>
                {addresses.length === 0 &&
                  'Address Information and Housing Status'}
                {addresses.length === 1 &&
                  'Previous Address Information and Housing Status'}
                {addresses.length === 2 &&
                  '2nd Previous Address Information and Housing Status'}
                {addresses.length === 3 &&
                  '3rd Previous Address Information and Housing Status'}
                {addresses.length > 3 &&
                  `${addresses.length}th Previous Address Information and Housing Status`}
              </h3>

              <div className="flex flex-row items-center">
                <form.Subscribe
                  selector={(state) => [state.values]}
                  children={([values]) => {
                    const canContinue =
                      !!values.street &&
                      !!values.zipCode &&
                      !!values.city &&
                      !!values.state &&
                      !!values.housingStatus &&
                      !!values.monthlyPayment &&
                      values.durationYears !== '' &&
                      (parseInt(values.durationYears.toString()) > 0 ||
                        parseInt(values.durationMonths.toString()) > 0);

                    return (
                      <Button
                        disabled={!canContinue || isDeleting}
                        loading={isSubmitting}
                        type="submit"
                        size="SMALL"
                        variant="TEXT_ONLY"
                      >
                        Save
                      </Button>
                    );
                  }}
                />

                <span className="icon-tertiary text-sm">|</span>

                <Button
                  size="SMALL"
                  variant="TEXT_ONLY"
                  onClick={() => handleDiscard()}
                >
                  Discard
                </Button>
              </div>
            </div>

            <AddressFormFields
              form={form}
              isSubmitting={isSubmitting}
              isDeleting={isDeleting}
            />
          </form>
        </form.Provider>
      )}
    </div>
  );
};

export default AddressForm;
