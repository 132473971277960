import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import { format } from 'date-fns';
import { hardCreditApplicationModification } from '~/lib/form/hard-credit-application-modification';
import { DealershipType } from '~/querySelectors/dealership';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import uploadFileAction from '../uploadFileAction';

const uploadHardCreditApplicationModificationAction = async (
  dealership: DealershipType,
  hardCreditApplication: HardCreditApplicationType,
  ipAddress: string
) => {
  // Generate modification notice
  const modificationPdf = await generatePdf(
    hardCreditApplicationModification,
    {
      dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
      name: `${hardCreditApplication.firstName ?? ''} ${hardCreditApplication.lastName ?? ''}`.trim(),
      ipAddress,
      deviceId: window.navigator.userAgent
    },
    false
  );
  const modificationPdfBlob = new Blob([modificationPdf]);

  return await uploadFileAction('pdf', 'application/pdf', modificationPdfBlob);
};

export default uploadHardCreditApplicationModificationAction;
