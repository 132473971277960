import { Dialog, Transition } from '@headlessui/react';
import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import {
  PrequalApplicationSchema,
  PrequalFormSchema
} from '@thedealersconcierge/lib/codecs/schema/prequalApplication';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { Fragment, useState } from 'react';
import { z } from 'zod';
import { cognitoRequestOtpAction } from '~/actions/auth/cognitoRequestOtpAction';
import { cognitoVerifyOtpAction } from '~/actions/auth/cognitoVerifyOtpAction';
import { hasExistingUserAction } from '~/actions/auth/hasExistingUser';

import requestOtpExistingUserDEVAction from '~/actions/auth/requestOtpExistingUserDEVAction';
import verifyOtpResponseAction from '~/actions/auth/verifyOtpResponseAction';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import Signature from '~/components/Signature';
import Spinner from '~/components/Spinner';
import CircleCheckMarkIcon from '~/components/icons/CircleCheckMarkIcon';
import Checkbox from '~/components/inputs/Checkbox';
import DateInput from '~/components/inputs/DateInput';
import DropDown from '~/components/inputs/DropDown';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import SocialSecurityNumberInput from '~/components/inputs/SocialSecurityNumberInput';
import TextInput from '~/components/inputs/TextInput';
import config from '~/config';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import dealershipQuery from '~/queries/dealershipQuery';
import { useParams } from '~/router';
import { authStateAtom } from '~/state/auth';

const PrequalData = PrequalApplicationSchema.pick({
  firstName: true,
  middleName: true,
  lastName: true,
  birthdate: true,
  socialSecurityNumber: true,
  street: true,
  apartmentDetails: true,
  state: true,
  city: true,
  zip: true
});

PrequalApplicationSchema;

type PrequalData = z.TypeOf<typeof PrequalData>;

const FormType = PrequalData.and(
  z.object({
    confirmNotAffectCreditScore: z.boolean(),
    hasPrequalConsented: z.boolean(),
    hasCommunicationsConsented: z.boolean(),

    phoneNumber: z.string(),
    email: z.string()
  })
);

type FormType = z.TypeOf<typeof FormType>;

const FileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 1.66663H5.00004C4.55801 1.66663 4.13409 1.84222 3.82153 2.15478C3.50897 2.46734 3.33337 2.89127 3.33337 3.33329V16.6666C3.33337 17.1087 3.50897 17.5326 3.82153 17.8451C4.13409 18.1577 4.55801 18.3333 5.00004 18.3333H15C15.4421 18.3333 15.866 18.1577 16.1786 17.8451C16.4911 17.5326 16.6667 17.1087 16.6667 16.6666V6.66663L11.6667 1.66663Z"
      stroke="#364154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6666 1.66663V6.66663H16.6666"
      stroke="#364154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 10.8334H6.66663"
      stroke="#364154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 14.1666H6.66663"
      stroke="#364154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33329 7.5H7.49996H6.66663"
      stroke="#364154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function WebPrequalPage() {
  const { dealershipSlug } = useParams('/prequal/:dealershipSlug');
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  // Error when logging in/verifying OTPs
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [code, setCode] = useState('');
  const [, setAuthState] = useAtom(authStateAtom);
  const { data: dealership, isLoading } = useQuery(
    dealershipQuery({ slug: dealershipSlug })
  );
  const { data: publicData, isLoading: publicIsLoading } = useQuery({
    queryKey: ['public'],
    queryFn: () => {
      return gqlQueryClient()({
        public: {
          ipAddress: true
        }
      });
    }
  });

  const [error, setError] = useState<string | null>(null);
  const [signature, setSignature] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // We use this to commit the form values when logging in
  // This is more convenience as we could have read the values out of the form
  // but it is darn ugly with all the getter functions we'd have to call
  const [submittedFormValues, setSubmittedFormValues] = useState<
    [FormType, PrequalFormSchema] | null
  >(null);

  const submitOtp = async (email: string, otp: string) => {
    try {
      setLoginError(null);
      setLoggingIn(true);

      if (config.useCognito) {
        await cognitoVerifyOtpAction(otp);
      } else {
        const verifyOtpResponse = await verifyOtpResponseAction(email, otp);
        if (!verifyOtpResponse.jwt) {
          throw new Error('No JWT returned');
        }
        setAuthState({ jwt: verifyOtpResponse.jwt });
      }

      if (!submittedFormValues) {
        throw new Error('No form data was submitted');
      }

      // Check if we need to create a transaction for an existing user
      const [value, formData] = submittedFormValues;

      const data = await gqlMutationClient()({
        submitPrequalApplicationAndCreateUser: [
          {
            dealershipId: dealership?.dealership.id ?? 'no-dealership-fetched',
            email: value.email,
            phoneNumber: value.phoneNumber,
            formData: formData
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationSubmitPrequalApplicationAndCreateUserSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (
        data.submitPrequalApplicationAndCreateUser.__typename !==
        'MutationSubmitPrequalApplicationAndCreateUserSuccess'
      ) {
        throw new Error(data.submitPrequalApplicationAndCreateUser.message);
      }

      // All is good, full success
      setAuthState(null);
      setIsOtpModalOpen(false);
      setIsSubmitted(true);
    } catch (e) {
      console.error(e);
      captureException(e);
      setLoginError('Could not login');
    } finally {
    }
  };

  const form = useForm<FormType, unknown>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',

      birthdate: new Date(),
      socialSecurityNumber: '',

      street: '',
      apartmentDetails: '',
      city: '',
      state: '',
      zip: '',

      phoneNumber: '',
      email: '',

      confirmNotAffectCreditScore: false,
      hasPrequalConsented: false,
      hasCommunicationsConsented: false
    },

    onSubmit: async (value) => {
      try {
        if (!signature) {
          throw new Error('No signature set');
        }
        const d: PrequalFormSchema = {
          state: value.state,
          // TODO: Acquire this
          suffix: '',
          middleName: value.middleName ?? '',
          birthdate: new Date(value.birthdate),
          dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
          name: `${value.firstName ?? ''} ${value.lastName ?? ''}`.trim(),

          deviceId: window.navigator.userAgent,
          street: value.street,
          apartmentDetails: value.apartmentDetails,
          city: value.city,
          zip: value.zip,
          signature: signature,
          socialSecurityNumber: value.socialSecurityNumber,
          firstName: value.firstName,
          lastName: value.lastName,

          ipAddress: publicData?.public.ipAddress ?? 'unknown'
        };
        // Just for good measure!
        const formData = PrequalFormSchema.parse(d);

        setSubmittedFormValues([value, formData]);

        const hasExistingUser = await hasExistingUserAction(value.email);

        if (!hasExistingUser) {
          const response = await gqlMutationClient()({
            submitPrequalApplicationAndCreateUser: [
              {
                dealershipId:
                  dealership?.dealership.id ?? 'no-dealership-fetched',
                email: value.email,
                phoneNumber: value.phoneNumber,
                formData: formData
              },
              {
                __typename: true,
                '...on GraphQLError': {
                  message: true
                },
                '...on MutationSubmitPrequalApplicationAndCreateUserSuccess': {
                  data: {
                    status: true
                  }
                }
              }
            ]
          });
          if (
            response.submitPrequalApplicationAndCreateUser.__typename ===
            'MutationSubmitPrequalApplicationAndCreateUserSuccess'
          ) {
            setIsSubmitted(true);
          } else {
            setError(
              response.submitPrequalApplicationAndCreateUser.message ??
                'An error happened'
            );
          }
        } else {
          if (config.useCognito) {
            await cognitoRequestOtpAction(value.email);
          } else {
            await requestOtpExistingUserDEVAction(value.email);
          }
          setIsOtpModalOpen(true);
        }
      } catch (e: any) {
        alert('An error happened');
      } finally {
      }
    }
  });

  // When designing this, please take into account that it is being shown in an iFrame.
  return (
    <>
      <Modal
        isOpen={isOtpModalOpen}
        title="Verification"
        className="p-6 mx-10"
        // We allow no control for the component
        onClose={() => {}}
      >
        <div className="flex flex-col items-center space-y-6">
          <div className="space-y-4">
            <p>
              Our records show that you already hold an account. We have sent a
              verification code to your phone and email.
            </p>

            <div className="space-y-2">
              <TextInput
                dataTestId="kiosk-create-enter-otp"
                placeholder="Code"
                labelText="Code"
                subtitleText="Code"
                required
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          </div>

          {loginError && (
            <div className="flex bg-negative-primary w-full py-1 rounded-md text-primary-inverse  items-center justify-center">
              <p>{loginError}</p>
            </div>
          )}

          <Button
            dataTestId="prequal-create-submit-otp"
            onClick={() => submitOtp(form.getFieldValue('email'), code)}
            disabled={code.length !== 6 || code.length !== 6}
            loading={loggingIn}
          >
            Submit
          </Button>
        </div>
      </Modal>
      {isSubmitted && (
        <Transition appear show={isSubmitted} as={Fragment}>
          {/* Can not close */}
          <Dialog as="div" className="relative z-30" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={
                      'w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6'
                    }
                  >
                    <div
                      className="py-4 text-center text-heading-3"
                      data-test-id="web-prequal-success"
                    >
                      <Dialog.Title className="">
                        Your Prequalification Application Was Submitted
                        Successfully!
                      </Dialog.Title>
                    </div>

                    <p className="flex justify-center">
                      <CircleCheckMarkIcon className="text-[#219653] w-8 h-8" />
                    </p>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      {isLoading && (
        <div className="flex w-screen h-screen items-center justify-center">
          <Spinner />
        </div>
      )}
      {!isLoading &&
        !Boolean(dealership?.dealership.hasEnabledPrequalFormService) && (
          <div className="flex w-screen h-screen items-center justify-center">
            <h1>No such dealership</h1>
          </div>
        )}
      {!isLoading &&
        Boolean(dealership?.dealership.hasEnabledPrequalFormService) && (
          <div className="w-screen h-screen overflow-auto">
            <form.Provider>
              <form
                className="flex justify-center"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  void form.handleSubmit();
                }}
              >
                <div className="flex flex-col max-w-[800px] divide-y">
                  {/* Title section */}
                  <div className="p-6 flex justify-center">
                    <div className="flex flex-row space-x-2 items-center">
                      <FileIcon />
                      <h2>Documents and Forms</h2>
                    </div>
                  </div>

                  {/* Heading section */}
                  <div className="p-6">
                    <h2 className="text-heading-2">
                      Pre-Qualification Application
                    </h2>

                    <p>
                      Once this form has been filled out, you will not be able
                      to edit it. Please read the information below: This next
                      step allows the dealer to view your credit score and
                      accurately check what programs are available for your
                      purchase. Note: This action will not affect your credit
                      score and is not considered a Hard Inquiry on your credit
                      file.
                    </p>
                  </div>

                  {error && (
                    <div className="bg-negative-secondary text-negative rounded-lg p-4 my-4">
                      {error}
                    </div>
                  )}

                  {/* Form section */}
                  <div className="p-6 flex flex-col bg-[#F9FAFC]">
                    <h2 className="text-heading-2">Basic Information</h2>

                    {/* Name line */}
                    <div className="grid grid-cols-6 md:grid-cols-12 gap-4">
                      <form.Field name="firstName">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-first-name"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="First Name"
                              placeholder="First Name"
                              subtitleText="First Name"
                              required
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="middleName">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-middle-name"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Middle Name"
                              subtitleText="Middle Name"
                              placeholder="Middle Name"
                              required
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="lastName">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-last-name"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Last Name"
                              placeholder="Last Name"
                              subtitleText="Last Name"
                              required
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      {/* Birthday SSN */}
                      <form.Field name="socialSecurityNumber">
                        {(field) => {
                          return (
                            <SocialSecurityNumberInput
                              dataTestId="web-prequal-ssn"
                              value={field.state.value}
                              fieldName={field.name}
                              subtitleText="Social Security Number"
                              placeholder="Social Security Number"
                              // disabled={
                              //   isSubmitting ||
                              //   !values.acknowledgesInformationUnderstanding
                              // }
                              required
                              onBlur={field.handleBlur}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              setFieldValue={(_fieldName, value) =>
                                form.setFieldValue(field.name, value)
                              }
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="birthdate">
                        {(field) => {
                          return (
                            <DateInput
                              dataTestId="web-prequal-birthdate"
                              fieldName={field.name}
                              value={new Date(field.state.value).toISOString()}
                              labelText="Birthdate"
                              subtitleText="Your Date of Birth"
                              placeholder="Birthdate"
                              required
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(new Date(e.target.value))
                              }
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      {/* Address */}
                      <form.Field name="street">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-street"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Street Address"
                              placeholder="Street Address"
                              subtitleText="Street Address"
                              required
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="apartmentDetails">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-apartment-details"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Apartment Details"
                              placeholder="Suite, Apartment #"
                              subtitleText="Apartment Details"
                              // disabled={
                              //   isSubmitting ||
                              //   !values.acknowledgesInformationUnderstanding
                              // }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      {/* City, state zip */}

                      <form.Field name="city">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-city"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="City"
                              placeholder="City"
                              subtitleText="City"
                              required
                              // disabled={
                              //   isSubmitting ||
                              //   !values.acknowledgesInformationUnderstanding
                              // }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="state">
                        {(field) => {
                          return (
                            <DropDown
                              dataTestId="web-prequal-state"
                              fieldName={field.name}
                              value={field.state.value}
                              options={stateOptions}
                              labelText="State"
                              placeholder="State"
                              subtitleText="State"
                              required
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="zip">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-zip"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="ZIP Code"
                              placeholder="ZIP Code"
                              subtitleText="ZIP Code"
                              required
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      {/* phone, email */}

                      <form.Field name="phoneNumber">
                        {(field) => {
                          return (
                            <PhoneNumberInput
                              dataTestId="web-prequal-phone-number"
                              fieldName={field.name}
                              value={field.state.value}
                              placeholder="Phone Number"
                              subtitleText="Phone Number"
                              onChange={(value) =>
                                field.handleChange(value?.toString() ?? '')
                              }
                              onBlur={field.handleBlur}
                              error={field.state.meta.touchedErrors.at(0)}
                              required
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="email">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-email"
                              fieldName={field.name}
                              value={field.state.value}
                              isEmail
                              labelText="Email"
                              placeholder="Email"
                              subtitleText="Email"
                              required
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(
                                  (e.target.value ?? '')
                                    .toLocaleLowerCase()
                                    .trim()
                                )
                              }
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>
                    </div>
                  </div>

                  {/* Consent section */}
                  <div className="p-6 space-y-4">
                    <h2 className="text-heading-2">
                      Our Commitment to Privacy
                    </h2>
                    <p>
                      All information stored in our database is secure and is
                      strictly confidential. Your personal and credit
                      information will only be used to fulfill your request and
                      in accordance with our{' '}
                      <a
                        target="_blank"
                        href="https://files.mytdc.net/privacy-policy-dealergenix-jan2024.pdf"
                        className="text-blue-500 hover:underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                    <form.Field
                      name="confirmNotAffectCreditScore"
                      validator={{
                        onChange: z.boolean().refine((val) => val, {})
                      }}
                      children={(field) => {
                        return (
                          <div className="flex flex-row space-x-2">
                            <Checkbox
                              dataTestId="web-prequal-confirm-not-affect-credit-score"
                              inputId={field.name}
                              value={field.state.value}
                              onChange={() =>
                                field.handleChange(!field.state.value)
                              }
                              containerClassName="pt-1"
                            />
                            <p>
                              {' '}
                              I understand that this is not an application for
                              credit, and submission will NOT affect credit
                              score.
                            </p>
                          </div>
                        );
                      }}
                    />
                    <form.Field
                      name="hasPrequalConsented"
                      validator={{
                        onChange: z.boolean().refine((val) => val, {})
                      }}
                      children={(field) => {
                        return (
                          <div className="flex flex-row space-x-2">
                            <Checkbox
                              dataTestId="web-prequal-has-prequal-consented"
                              inputId={field.name}
                              value={field.state.value}
                              onChange={() =>
                                field.handleChange(!field.state.value)
                              }
                              containerClassName="pt-1"
                            />
                            <p>
                              I hereby provide my consent to have my credit file
                              accessed for purposes of prequalifying for a
                              vehicle loan. This is a soft inquiry and will not
                              impact my credit score. I agree to the{' '}
                              <a
                                className="text-blue-500 hover:underline"
                                href={`${config.rawBackendUrl}/document/${dealershipSlug}/privacy-notice.pdf`}
                              >
                                Privacy Notice
                              </a>
                              ,{' '}
                              <a
                                target="_blank"
                                className="text-blue-500 hover:underline"
                                href="https://files.mytdc.net/prequal-terms-and-conditions.pdf"
                              >
                                Terms and Conditions
                              </a>{' '}
                              and I acknowledge I may be contacted by{' '}
                              <b>{dealership?.dealership.name}</b>. I understand
                              that I might not prequalify depending on the
                              prequalification criteria.
                            </p>
                          </div>
                        );
                      }}
                    />
                    <form.Field
                      name="hasCommunicationsConsented"
                      children={(field) => {
                        return (
                          <div className="flex flex-row space-x-2">
                            <Checkbox
                              dataTestId="web-prequal-communication-consent"
                              inputId={field.name}
                              value={field.state.value}
                              onChange={() =>
                                field.handleChange(!field.state.value)
                              }
                              containerClassName="pt-1"
                            />
                            <p className="text-secondary">
                              I hereby provide my consent to receive all
                              disclosures electronically via the TDC Buyer
                              Portal. I have been duly informed regarding the
                              procedures for accessing this portal, and I have
                              access to my email address. Additionally, I
                              acknowledge that I may request and receive printed
                              versions of these disclosures.
                            </p>
                          </div>
                        );
                      }}
                    />
                    <div>
                      Signature
                      <Signature
                        dataTestIdOpenModal="web-prequal-signature-open-modal"
                        dataTestIdCanvas="web-prequal-signature-canvas"
                        dataTestIdSubmit="web-prequal-submit-signature"
                        onSignatureSubmit={setSignature}
                        existingSignature={signature}
                      />
                    </div>
                  </div>

                  {/* Submit */}
                  <div className="p-6 flex justify-end">
                    <form.Subscribe>
                      {(f) => (
                        <Button
                          dataTestId="web-prequal-submit"
                          variant="PRIMARY"
                          loading={f.isSubmitting}
                          disabled={
                            !(
                              f.values.hasCommunicationsConsented &&
                              f.values.confirmNotAffectCreditScore &&
                              f.values.hasPrequalConsented &&
                              Boolean(signature)
                            )
                          }
                        >
                          Submit
                        </Button>
                      )}
                    </form.Subscribe>
                  </div>
                </div>
              </form>
            </form.Provider>
          </div>
        )}
    </>
  );
}
