import { FormApi, useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { parsePhoneNumber } from 'libphonenumber-js';
import { FC, Fragment, useCallback, useState } from 'react';
import createAddressAction from '~/actions/addresses/createAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import createEmploymentAction from '~/actions/employments/createEmploymentAction';
import deleteEmploymentAction from '~/actions/employments/deleteEmploymentAction';
import updateEmploymentAction from '~/actions/employments/updateEmploymentAction';
import Button from '~/components/Button';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DropDown from '~/components/inputs/DropDown';
import NumberInput, {
  parseNumberInputValue
} from '~/components/inputs/NumberInput';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import TextInput from '~/components/inputs/TextInput';
import Spinner from '~/components/Spinner';
import employmentStatusOptions from '~/config/formSelectionOptions/employmentStatusOptions';
import incomeIntervalOptions from '~/config/formSelectionOptions/incomeIntervalOptions';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import customerQuery from '~/queries/customerQuery';
import { resetHardCreditQuery } from '~/queries/hardCreditQuery';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import { EmploymentType } from '~/querySelectors/employment';
import { Link, useNavigate, useParams } from '~/router';

const HardCreditApplicationEmploymentInfoForm: FC<{
  employments?: EmploymentType[];
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  employments,
  isDeleting,
  setIsDeleting,
  isSubmitting,
  setIsSubmitting
}) => {
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo'
  );
  const [timelinePosition, setTimelinePosition] = useState(
    employments?.length ?? 0
  );
  const [isEditing, setIsEditing] = useState(false);
  const currentEmployment = employments?.find(
    (e) => e.timelinePosition === timelinePosition
  );
  const moreThanTwoYears =
    (employments
      ?.map((e) => e.durationYears + e.durationMonths / 12)
      .reduce((total, duration) => duration + total, 0) ?? 0) >= 2;
  const handleContinue = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/personalReference',
      {
        params: {
          dealershipSlug,
          transactionId,
          hardCreditApplicationId
        }
      }
    );
  };
  const handleSubmit = useCallback(
    async (values: any, form: FormApi<any, unknown>) => {
      setIsSubmitting(true);

      if (currentEmployment) {
        let employerAddressId: string | undefined = undefined;

        // Update or create the employer address if necessary
        if (
          ['EMPLOYED', 'SELF_EMPLOYED'].includes(values.employmentStatus ?? '')
        ) {
          if (currentEmployment.employerAddress?.id) {
            employerAddressId = currentEmployment.employerAddress.id;

            await updateAddressAction(employerAddressId ?? 'no-address-id', {
              street: values['employerStreet'],
              apartmentDetails: values['employerApartmentDetails'],
              zipCode: values['employerZipCode'],
              state: values['employerState'],
              city: values['employerCity']
            });
          } else {
            const createdAddress = await createAddressAction({
              street: values['employerStreet'],
              apartmentDetails: values['employerApartmentDetails'],
              zipCode: values['employerZipCode'],
              state: values['employerState'],
              city: values['employerCity']
            });

            employerAddressId = createdAddress.id;
          }
        }

        await updateEmploymentAction(currentEmployment.id, {
          employmentStatus: values['employmentStatus'],
          employerName: values['employerName'],
          occupation: values['occupation'],
          employerPhoneNumber: values['employerPhoneNumber'].length // We have to check whether the phone number has been set becausean empty string will fail the phone number validation
            ? values['employerPhoneNumber']
            : undefined,
          employerAddressId,
          durationYears: parseInt(values['durationYears']),
          durationMonths: values['durationMonths']
            ? parseInt(values['durationMonths'])
            : 0,
          incomeAmount: parseNumberInputValue(values['incomeAmount']),
          incomeInterval: values['incomeInterval'],
          otherIncomeSource: values['otherIncomeSource'],
          otherIncomeAmount: parseNumberInputValue(values['otherIncomeAmount']),
          otherIncomeInterval: values['otherIncomeInterval'].length
            ? values['otherIncomeInterval']
            : undefined, // otherIncomeInterval is optional, so we have to pass undefined if the value is an empty string
          timelinePosition
        });

        setTimelinePosition(employments?.length ?? 0);
      } else {
        let employerAddressId: string | undefined = undefined;

        // Create the employer address if necessary
        if (
          ['EMPLOYED', 'SELF_EMPLOYED'].includes(values.employmentStatus ?? '')
        ) {
          const createdAddress = await createAddressAction({
            street: values['employerStreet'],
            apartmentDetails: values['employerApartmentDetails'],
            zipCode: values['employerZipCode'],
            state: values['employerState'],
            city: values['employerCity']
          });

          employerAddressId = createdAddress.id;
        }

        await createEmploymentAction(transactionId, {
          employmentStatus: values['employmentStatus'],
          employerName: values['employerName'],
          occupation: values['occupation'],
          employerPhoneNumber: values['employerPhoneNumber'].length // We have to check whether the phone number has been set becausean empty string will fail the phone number validation
            ? values['employerPhoneNumber']
            : undefined,
          employerAddressId,
          durationYears: parseNumberInputValue(values['durationYears']) ?? 0,
          durationMonths: values['durationMonths']
            ? (parseNumberInputValue(values['durationMonths']) ?? 0)
            : 0,
          incomeAmount: parseNumberInputValue(values['incomeAmount']) ?? 0,
          incomeInterval: values['incomeInterval'],
          otherIncomeSource: values['otherIncomeSource'],
          otherIncomeAmount: parseNumberInputValue(values['otherIncomeAmount']),
          otherIncomeInterval: values['otherIncomeInterval'].length
            ? values['otherIncomeInterval']
            : undefined, // otherIncomeInterval is optional, so we have to pass undefined if the value is an empty string
          timelinePosition
        });

        setTimelinePosition((employments?.length ?? 0) + 1);
      }

      await Promise.all([
        await resetMeQuery(),
        await resetHardCreditQuery(transactionId)
      ]);

      form.reset();
      setIsSubmitting(false);
      setIsEditing(false);
    },
    [
      timelinePosition,
      currentEmployment,
      transactionId,
      setIsSubmitting,
      setTimelinePosition,
      employments
    ]
  );
  const handleDelete = async (employmentId: string) => {
    setIsDeleting(true);

    await deleteEmploymentAction(employmentId);
    setTimelinePosition(
      employments?.filter((e) => e.id !== employmentId).length ?? 0
    );

    await Promise.all([
      await resetMeQuery(),
      await resetHardCreditQuery(transactionId)
    ]);

    form.reset();
    setIsEditing(false);
    setIsDeleting(false);
  };
  const form = useForm({
    defaultValues: {
      employmentStatus: currentEmployment?.employmentStatus ?? '',
      employerName: currentEmployment?.employerName ?? '',
      occupation: currentEmployment?.occupation ?? '',
      employerPhoneNumber: currentEmployment?.employerPhoneNumber ?? '',
      employerStreet: currentEmployment?.employerAddress?.street ?? '',
      employerApartmentDetails:
        currentEmployment?.employerAddress?.apartmentDetails ?? '',
      employerZipCode: currentEmployment?.employerAddress?.zipCode ?? '',
      employerCity: currentEmployment?.employerAddress?.city ?? '',
      employerState: currentEmployment?.employerAddress?.state ?? '',
      durationYears: currentEmployment?.durationYears.toString() ?? '',
      durationMonths: currentEmployment?.durationMonths.toString() ?? '',
      incomeAmount: currentEmployment?.incomeAmount.toString() ?? '',
      incomeInterval: currentEmployment?.incomeInterval ?? '',
      otherIncomeSource: currentEmployment?.otherIncomeSource ?? '',
      otherIncomeAmount: currentEmployment?.otherIncomeAmount?.toString() ?? '',
      otherIncomeInterval: currentEmployment?.otherIncomeInterval ?? ''
    },
    onSubmit: handleSubmit
  });
  const handleEdit = (pos: number) => {
    const employment = employments?.find((e) => e.timelinePosition === pos);

    form.setFieldValue('employmentStatus', employment?.employmentStatus ?? '');
    form.setFieldValue('employerName', employment?.employerName ?? '');
    form.setFieldValue('occupation', employment?.occupation ?? '');
    form.setFieldValue(
      'employerPhoneNumber',
      employment?.employerPhoneNumber ?? ''
    );
    form.setFieldValue(
      'employerStreet',
      employment?.employerAddress?.street ?? ''
    );
    form.setFieldValue(
      'employerApartmentDetails',
      employment?.employerAddress?.apartmentDetails ?? ''
    );
    form.setFieldValue(
      'employerZipCode',
      employment?.employerAddress?.zipCode ?? ''
    );
    form.setFieldValue('employerCity', employment?.employerAddress?.city ?? '');
    form.setFieldValue(
      'employerState',
      employment?.employerAddress?.state ?? ''
    );
    form.setFieldValue(
      'durationYears',
      employment?.durationYears.toString() ?? ''
    );
    form.setFieldValue(
      'durationMonths',
      employment?.durationMonths.toString() ?? ''
    );
    form.setFieldValue(
      'incomeAmount',
      employment?.incomeAmount.toString() ?? ''
    );
    form.setFieldValue('incomeInterval', employment?.incomeInterval ?? '');
    form.setFieldValue(
      'otherIncomeSource',
      employment?.otherIncomeSource ?? ''
    );
    form.setFieldValue(
      'otherIncomeAmount',
      employment?.otherIncomeAmount?.toString() ?? ''
    );
    form.setFieldValue(
      'otherIncomeInterval',
      employment?.otherIncomeInterval ?? ''
    );
    setTimelinePosition(pos);
    setIsEditing(true);
  };
  const renderEmployment = (pos: number) => {
    const employment = employments?.find((e) => e.timelinePosition === pos);

    if (!employment) {
      return undefined;
    }

    return (
      <div
        key={pos}
        className="flex flex-col space-y-4 w-full rounded-lg overflow-hidden shadow-border p-4"
      >
        <div className="flex flex-col-reverse md:flex-row md:justify-between gap-2">
          <h2>
            {pos === 0 && 'Current Employment'}
            {pos === 1 && 'Previous Employment'}
            {pos === 2 && '2nd Previous Employment'}
            {pos === 3 && '3rd Previous Employment'}
            {pos > 3 && `${pos}th Previous Employment`}
          </h2>

          <div className="flex flex-row items-center justify-end">
            <Button
              size="SMALL"
              variant="TEXT_ONLY"
              onClick={() => handleEdit(pos)}
              disabled={isSubmitting || isDeleting}
            >
              Edit
            </Button>

            <span className="icon-tertiary text-sm">|</span>

            <Button
              size="SMALL"
              variant="TEXT_ONLY"
              disabled={(employments?.length ?? 0) < 2 || isSubmitting}
              onClick={() => handleDelete(employment.id)}
              loading={isDeleting}
            >
              Delete
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {employment.employerName && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Employer Name:</p>

              <p className="text-secondary">{employment.employerName}</p>
            </div>
          )}

          {(employment.occupation || employment.employerPhoneNumber) && (
            <Fragment>
              <div className="space-y-2">
                <p className="text-body-2 text-tertiary">Occupation:</p>

                <p className="text-secondary">{employment.occupation}</p>
              </div>

              <div className="space-y-2">
                <p className="text-body-2 text-tertiary">
                  Employer Phone Number:
                </p>

                <p className="text-secondary">
                  {employment.employerPhoneNumber
                    ? parsePhoneNumber(employment.employerPhoneNumber).format(
                        parsePhoneNumber(employment.employerPhoneNumber)
                          ?.country === 'US'
                          ? 'NATIONAL'
                          : 'INTERNATIONAL'
                      )
                    : ''}
                </p>
              </div>
            </Fragment>
          )}

          {employment.employerAddress && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Employer Address:</p>

              <p className="text-secondary">
                {employment.employerAddress.fullAddress}
              </p>
            </div>
          )}

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">Employment Status:</p>

            <p className="text-secondary">
              {
                employmentStatusOptions.find(
                  (o) => o.value === employment.employmentStatus
                )?.label
              }
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">Employment Duration:</p>

            <p className="text-secondary">
              {employment.durationYears} Yrs {employment.durationMonths} Mos
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">Salary:</p>

            <p className="text-secondary">
              ${employment.incomeAmount.toLocaleString()}
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">Per Time Period:</p>

            <p className="text-secondary">
              {
                incomeIntervalOptions.find(
                  (o) => o.value === employment.incomeInterval
                )?.label
              }
            </p>
          </div>

          {employment.otherIncomeSource && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Other Income Source:</p>

              <p className="text-secondary">{employment.otherIncomeSource}</p>
            </div>
          )}

          {employment.otherIncomeAmount && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Income Amount:</p>

              <p className="text-secondary">
                ${employment.otherIncomeAmount.toLocaleString()}
              </p>
            </div>
          )}

          {employment.otherIncomeInterval && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Per Time Period:</p>

              <p className="text-secondary">
                {
                  incomeIntervalOptions.find(
                    (o) => o.value === employment.otherIncomeInterval
                  )?.label
                }
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-8 md:space-y-16 w-full items-center">
      {(!moreThanTwoYears || isEditing) && (
        <div className="flex flex-col w-full space-y-4 bg-secondary border-y border-secondary items-center py-6 px-6">
          <div className="flex flex-row justify-between w-full max-w-screen-md items-center">
            <h2>
              {timelinePosition === 0 && 'Employment Information'}
              {timelinePosition === 1 && 'Previous Employment Information'}
              {timelinePosition === 2 && '2nd Previous Employment Information'}
              {timelinePosition === 3 && '3rd Previous Employment Information'}
              {timelinePosition > 3 &&
                `${timelinePosition}th Previous Employment Information`}
            </h2>
          </div>

          <form.Provider>
            <form
              className="grid grid-cols-2 md:grid-cols-8 gap-4 w-full max-w-screen-md items-end"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <form.Field name="employmentStatus">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={employmentStatusOptions}
                      labelText="Employment Status"
                      subtitleText="Employment Status"
                      placeholder="Employment Status"
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="col-span-2 md:col-span-8"
                    />
                  );
                }}
              </form.Field>

              <form.Subscribe
                selector={(state) => [state.values]}
                children={([values]) => {
                  return (
                    <Fragment>
                      {['EMPLOYED', 'SELF_EMPLOYED'].includes(
                        values.employmentStatus ?? ''
                      ) && (
                        <Fragment>
                          <form.Field name="employerName">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText="Employer Name"
                                  subtitleText="Employer Name"
                                  placeholder="Employer Name"
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) =>
                                    field.handleChange(e.target.value)
                                  }
                                  containerClassName="col-span-2 md:col-span-8"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerStreet">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText="Employer Address"
                                  subtitleText="Employer Address"
                                  placeholder="Employer Address"
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) =>
                                    field.handleChange(e.target.value)
                                  }
                                  containerClassName="col-span-2 md:col-span-4"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerApartmentDetails">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText="Apartment Details"
                                  subtitleText="Apartment Details"
                                  placeholder="Suite, Apt #"
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) =>
                                    field.handleChange(e.target.value)
                                  }
                                  containerClassName="md:col-span-4"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerZipCode">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText="Zip Code"
                                  subtitleText="Zip Code"
                                  placeholder="Zip Code"
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) =>
                                    field.handleChange(e.target.value)
                                  }
                                  containerClassName="md:col-span-3"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerCity">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText="City"
                                  subtitleText="City"
                                  placeholder="City"
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) =>
                                    field.handleChange(e.target.value)
                                  }
                                  containerClassName="md:col-span-2"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerState">
                            {(field) => {
                              return (
                                <DropDown
                                  fieldName={field.name}
                                  value={field.state.value}
                                  options={stateOptions}
                                  labelText="State"
                                  placeholder="State"
                                  subtitleText="State"
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) =>
                                    field.handleChange(e.target.value)
                                  }
                                  containerClassName="md:col-span-3"
                                />
                              );
                            }}
                          </form.Field>

                          {/** The naming of the field changed in the designs. So label/subtitle texts are different than the variable name */}
                          <form.Field name="occupation">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText="Role"
                                  subtitleText="Role"
                                  placeholder="Role"
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) =>
                                    field.handleChange(e.target.value)
                                  }
                                  containerClassName="col-span-2 md:col-span-8"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerPhoneNumber">
                            {(field) => {
                              return (
                                <PhoneNumberInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  subtitleText="Employer Phone Number"
                                  placeholder="Employer Phone Number"
                                  onChange={(value) =>
                                    field.handleChange(value?.toString() ?? '')
                                  }
                                  onBlur={field.handleBlur}
                                  disabled={isSubmitting || isDeleting}
                                  required
                                  containerClassName="col-span-2 md:col-span-8"
                                />
                              );
                            }}
                          </form.Field>
                        </Fragment>
                      )}

                      <form.Field name="durationYears">
                        {(field) => {
                          return (
                            <NumberInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Duration in Years"
                              subtitleText="How many years have you been working at this job?"
                              placeholder="Duration (Yrs.)"
                              required
                              suffix=" Yrs."
                              disabled={isSubmitting || isDeleting}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="durationMonths">
                        {(field) => {
                          return (
                            <NumberInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Duration in Months"
                              subtitleText="How many months have you been working at this job?"
                              placeholder="Duration (Mos.)"
                              suffix=" Mos."
                              disabled={isSubmitting || isDeleting}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-4 h-full"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="incomeAmount">
                        {(field) => {
                          return (
                            <NumberInput
                              fieldName={field.name}
                              value={field.state.value}
                              subtitleText="Gross Income"
                              placeholder="Gross Income"
                              disabled={isSubmitting || isDeleting}
                              prefix="$"
                              required
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="incomeInterval">
                        {(field) => {
                          return (
                            <DropDown
                              fieldName={field.name}
                              value={field.state.value}
                              options={incomeIntervalOptions}
                              labelText="Per Time Period"
                              subtitleText="Per Time Period"
                              placeholder="Per Time Period"
                              required
                              disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="otherIncomeSource">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Other Income Source"
                              subtitleText="Other Income Source"
                              placeholder="Other Income Source"
                              disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-8"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="otherIncomeAmount">
                        {(field) => {
                          return (
                            <NumberInput
                              fieldName={field.name}
                              value={field.state.value}
                              subtitleText="Gross Income"
                              placeholder="Gross Income"
                              disabled={isSubmitting || isDeleting}
                              prefix="$"
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="otherIncomeInterval">
                        {(field) => {
                          return (
                            <DropDown
                              fieldName={field.name}
                              value={field.state.value}
                              options={incomeIntervalOptions}
                              labelText="Per Time Period"
                              subtitleText="Per Time Period"
                              placeholder="Per Time Period"
                              disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-2 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>
                    </Fragment>
                  );
                }}
              />

              <div className="flex flex-row col-span-2 md:col-span-8 justify-end">
                <form.Subscribe
                  selector={(state) => [state.values]}
                  children={([values]) => {
                    let canContinue =
                      !!values.employmentStatus &&
                      !!values.incomeAmount &&
                      !!values.incomeInterval &&
                      values.durationYears !== '' &&
                      (parseInt(values.durationYears.toString()) > 0 ||
                        parseInt(values.durationMonths.toString()) > 0);

                    if (
                      canContinue &&
                      ['EMPLOYED', 'SELF_EMPLOYED'].includes(
                        values.employmentStatus ?? ''
                      )
                    ) {
                      canContinue =
                        !!values.employerName &&
                        !!values.occupation &&
                        !!values.employerPhoneNumber &&
                        !!values.employerStreet &&
                        !!values.employerZipCode &&
                        !!values.employerCity &&
                        !!values.employerState;
                    }

                    return (
                      <Button
                        disabled={!canContinue || isDeleting}
                        loading={isSubmitting}
                        type="submit"
                      >
                        Save
                      </Button>
                    );
                  }}
                />
              </div>
            </form>
          </form.Provider>
        </div>
      )}

      {!!employments?.length && (
        <div className="flex w-full px-6 justify-center">
          <div className="flex flex-col space-y-12 w-full max-w-screen-md bg-primary">
            {[
              ...new Array(employments.length),
              !moreThanTwoYears ? {} : 'SKIP'
            ].map((e, index) => {
              if (e !== 'SKIP') {
                return renderEmployment(index);
              }
            })}
          </div>
        </div>
      )}

      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-row justify-end w-full max-w-screen-md">
          <Button
            onClick={handleContinue}
            disabled={isSubmitting || !moreThanTwoYears || isDeleting}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

const HardCreditApplicationEmploymentInfoPage = () => {
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo'
  );
  const { data: meData } = useQuery(meQuery());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { data: customer, isFetched } = useQuery(
    customerQuery(transactionId, meData?.me?.user.id, dealershipSlug)
  );

  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };

  const employments = customer?.customer.employments;

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="Hard Credit Application"
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting || isDeleting}
          >
            Cancel
          </button>
        }
        totalSteps={5}
        currentStep={3}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo"
            params={{ transactionId, hardCreditApplicationId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col py-10 space-y-6 w-full items-center overflow-y-scroll h-full">
        {isFetched ? (
          <Fragment>
            <div className="flex w-full px-6 justify-center">
              <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
                <h2>Credit Application</h2>

                <p>
                  Including the last two years of your employment is required.
                </p>
              </div>
            </div>

            <HardCreditApplicationEmploymentInfoForm
              employments={employments?.edges.map((e) => e.node)}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          </Fragment>
        ) : (
          <div className="flex w-full aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HardCreditApplicationEmploymentInfoPage;
