import store from '~/lib/store';
import { userPool } from '~/lib/userpool';
import { authStateAtom, cognitoUserAtom } from '~/state/auth';

export const cognitoVerifyOtpAction = async (otp: string): Promise<'OK'> => {
  if (!userPool) {
    throw new Error('Cognito user pool was not loaded');
  }

  const user = store.instance.get(cognitoUserAtom);

  if (!user) {
    throw new Error('No used found');
  }

  return new Promise<'OK'>((resolve, reject) => {
    user.sendCustomChallengeAnswer(otp, {
      onFailure: (failure) => {
        reject(failure);
      },
      onSuccess: async (resp) => {
        store.instance.set(authStateAtom, {
          accessToken: resp.getAccessToken().getJwtToken(),
          idToken: resp.getIdToken().getJwtToken(),
          refreshToken: resp.getRefreshToken().getToken()
        });
        resolve('OK');
      }
    });
  });
};
