import { gqlMutationClient } from '~/lib/backend';

const verifyOtpResponseAction = async (email: string, otp: string) => {
  const resp = await gqlMutationClient()({
    verifyOtpForExistingUser: [
      {
        email,
        emailCode: otp
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationVerifyOtpForExistingUserSuccess': {
          data: {
            jwt: true
          }
        }
      }
    ]
  });

  if (resp.verifyOtpForExistingUser.__typename === 'GraphQLError') {
    throw new Error(resp.verifyOtpForExistingUser.message);
  }

  return resp.verifyOtpForExistingUser.data;
};

export default verifyOtpResponseAction;
