import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';

type Fields = 'front' | 'back' | 'name' | 'dateTime' | 'deviceId' | 'ipAddress';
export const identityVerification: DocumentTemplate<Fields> = {
  fileUrl: '/docs/identity-template.pdf',
  fields: {
    front: {
      formFieldId: 'form-identity-verification-field-1',
      formId: 'form-identity-verification',
      type: 'IMAGE',
      formFieldPlacements: [
        {
          formFieldPlacementId:
            'form-identity-verification-field-1-placement-1',
          positionX: 80,
          positionY: 300,
          page: 0,
          width: 200,
          height: 133,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      createdAt: '2024-05-21 00:00:00.000'
    },
    back: {
      formFieldId: 'form-identity-verification-field-1',
      formId: 'form-identity-verification',
      type: 'IMAGE',
      formFieldPlacements: [
        {
          formFieldPlacementId:
            'form-identity-verification-field-1-placement-1',
          positionX: 320,
          positionY: 300,
          page: 0,
          width: 200,
          height: 133,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      createdAt: '2024-05-21 00:00:00.000'
    },
    ...legalStamps('form-identity-verification', [0])
  }
};
