import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import Button from '~/components/Button';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import NumberInput, {
  parseNumberInputValue
} from '~/components/inputs/NumberInput';
import SelectableRow from '~/components/SelectableRow';
import Spinner from '~/components/Spinner';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import { queryClient } from '~/main';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import { Link, useNavigate, useParams } from '~/router';

const TradeVehiclePayOffForm: FC<{
  wantsPayOff?: boolean;
  currentMonthlyPayment?: number;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  currentMonthlyPayment,
  wantsPayOff,
  isSubmitting,
  setIsSubmitting
}) => {
  const navigate = useNavigate();
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff'
  );
  const handleGoBack = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information',
      {
        params: { transactionId, vehicleId, dealershipSlug }
      }
    );
  };
  // We are storing the data in state variables instead of reading directly from the atom as the user has to confirm the update
  const [wantsPayOffSelection, setWantsPayOffSelection] =
    useState(!!wantsPayOff);
  const form = useForm({
    defaultValues: {
      currentMonthlyPayment: currentMonthlyPayment?.toString() ?? ''
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);

      const response = await gqlMutationClient({ dealershipSlug })({
        updateCustomer: [
          {
            transactionId,
            customer: {
              wantsPayOff: wantsPayOffSelection,
              currentMonthlyPayment: parseNumberInputValue(
                values.currentMonthlyPayment
              )
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationUpdateCustomerSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (
        response.updateCustomer.__typename === 'MutationUpdateCustomerSuccess'
      ) {
        await Promise.all([
          await resetMeQuery(),
          await queryClient.resetQueries({
            queryKey: ['transaction', transactionId]
          })
        ]);

        navigate(
          '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/pictures',
          {
            params: { transactionId, vehicleId, dealershipSlug }
          }
        );
      } else {
        console.error(response.updateCustomer.message);
        window.alert(
          'Could not save data. Please try again or contact support.'
        );
      }

      setIsSubmitting(false);
    }
  });

  return (
    <div className="flex flex-col items-center overflow-y-scroll px-6">
      <div className="flex flex-col max-w-screen-md py-6 md:py-10 justify-between w-full">
        <form.Provider>
          <form
            className="space-y-8 md:space-y-16"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
          >
            <h2>Step 3: Pay-Off</h2>

            <div className="flex flex-col space-y-4">
              <p>Would you like to get a pay-off?</p>

              <div>
                <SelectableRow
                  selected={wantsPayOffSelection}
                  text="Yes"
                  onClick={() => setWantsPayOffSelection(true)}
                  disabled={isSubmitting}
                />

                <SelectableRow
                  selected={!wantsPayOffSelection}
                  text="No"
                  onClick={() => setWantsPayOffSelection(false)}
                  disabled={isSubmitting}
                />
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <p>How much is your current monthly payment?</p>

              <form.Field name="currentMonthlyPayment">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Current monthly payment"
                      subtitleText="Current monthly payment"
                      prefix="$"
                      suffix="/month"
                      placeholder="/month"
                      onChange={(e) => field.handleChange(e.target.value)}
                    />
                  );
                }}
              </form.Field>
            </div>

            <div className="flex flex-row justify-between">
              <Button
                variant="TERTIARY"
                disabled={isSubmitting}
                onClick={handleGoBack}
              >
                Back
              </Button>

              <Button type="submit" loading={isSubmitting}>
                Next
              </Button>
            </div>
          </form>
        </form.Provider>
      </div>
    </div>
  );
};

const TradeVehiclePayOffPage = () => {
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const { data: userData, isLoading: loadingUserData } = useQuery(meQuery());
  const { data: customerData, isLoading: loadingCustomer } = useQuery({
    queryKey: ['transaction', transactionId, 'customer', 'payoff'],
    queryFn: async () =>
      gqlQueryClient()({
        customer: [
          {
            userId: userData?.me.user.id ?? 'no-user-id',
            transactionId
          },
          {
            wantsPayOff: true,
            currentMonthlyPayment: true
          }
        ]
      }),
    enabled: !!userData?.me.user.id
  });

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="Trade Registration"
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Cancel
          </button>
        }
        totalSteps={4}
        currentStep={3}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information"
            params={{ transactionId, vehicleId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      {(loadingCustomer || loadingUserData) && (
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      )}

      {!loadingCustomer && !loadingUserData && (
        <TradeVehiclePayOffForm
          wantsPayOff={customerData?.customer.wantsPayOff}
          currentMonthlyPayment={customerData?.customer.currentMonthlyPayment}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </div>
  );
};

export default TradeVehiclePayOffPage;
