import { FC, useState } from 'react';
import removeCoBuyerAction from '~/actions/removeCoBuyerAction';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { formatPhoneNumber } from '~/lib/utils';
import {
  TransactionQueryType,
  resetTransactionQuery
} from '~/queries/transactionQuery';
import { useNavigate, useParams } from '~/router';

/**
 * Shows the opposite part of the currently logged in part
 *
 * @param param0
 * @returns
 */
const ParaPartDataSection: FC<{
  seeForRole: 'BUYER' | 'CO_BUYER';
  transaction: TransactionQueryType['transaction'];
}> = ({ seeForRole, transaction }) => {
  // This is a section type component, hence we know it is only used once
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId'
  );

  const [isRemoveCoBuyerModalOpen, setIsRemoveCoBuyerModalOpen] =
    useState(false);
  const navigate = useNavigate();

  const handleCloseRemoveCoBuyerModal = () => {
    setIsRemoveCoBuyerModalOpen(false);
  };

  const customerFullName =
    seeForRole === 'BUYER'
      ? transaction.customerSharedData.buyerName
      : transaction.customerSharedData.coBuyerName;

  const userEmail =
    seeForRole === 'BUYER'
      ? transaction.customerSharedData.buyerEmail
      : transaction.customerSharedData.coBuyerEmail;

  const userPhoneNumber =
    seeForRole === 'BUYER'
      ? transaction.customerSharedData.buyerPhoneNumber
      : transaction.customerSharedData.coBuyerPhoneNumber;

  const handleRemoveCoBuyer = async () => {
    await removeCoBuyerAction(transaction.id);
    handleCloseRemoveCoBuyerModal();
    resetTransactionQuery(transaction.id, dealershipSlug);
  };

  const handleGoToCoBuyer = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId/coBuyer', {
      params: { transactionId, dealershipSlug }
    });
  };

  const haveCoBuyer = Boolean(transaction.coBuyerId);

  return (
    <div className="flex flex-col h-full">
      {/**
       * The transaction doesn't have a co-buyer
       */}
      {!haveCoBuyer && (
        <div className="bg-primary rounded-lg shadow-border p-6 flex-col space-y-4 h-full">
          <h2>Co-Buyer</h2>

          <Button onClick={handleGoToCoBuyer}>+ Add</Button>
        </div>
      )}

      {/**
       * The transaction does have a co-buyer
       */}
      {haveCoBuyer && (
        <div className="bg-primary rounded-lg shadow-border p-6 flex-col space-y-4 h-full">
          <h2>{seeForRole === 'CO_BUYER' ? 'Co-Buyer' : 'Primary Buyer'}</h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex flex-col space-y-1">
              <p className="text-body-2 font-bold uppercase text-tertiary">
                Name
              </p>

              <p>{customerFullName ?? '-'}</p>
            </div>

            <div className="md:col-span-2 flex flex-col space-y-1">
              <p className="text-body-2 font-bold uppercase text-tertiary">
                Email
              </p>

              <p className="overflow-ellipsis overflow-hidden">
                {userEmail ?? '-'}
              </p>
            </div>

            <div className="flex flex-col space-y-1 mt-2">
              <p className="text-body-2 font-bold uppercase text-tertiary">
                Phone
              </p>

              <p>{formatPhoneNumber(userPhoneNumber) ?? '-'}</p>
            </div>
          </div>

          {/* This is assuming the the logged in part is the buyer */}
          {seeForRole === 'CO_BUYER' && (
            <div className="flex flex-row justify-end">
              <Button
                variant="TEXT_ONLY"
                size="DEFAULT"
                onClick={() => setIsRemoveCoBuyerModalOpen(true)}
                className="pr-0 pb-0"
              >
                Remove Co-Buyer
              </Button>
            </div>
          )}
        </div>
      )}

      <Modal
        isOpen={!!isRemoveCoBuyerModalOpen}
        title="Remove Co-Buyer"
        className="p-6 mx-10"
        onClose={handleCloseRemoveCoBuyerModal}
      >
        <div className="flex flex-col items-center space-y-6">
          <div className="space-y-4">
            <p className="text-center">
              Are you sure you want to remove{' '}
              <strong>{customerFullName ?? ''}</strong> from the transaction?
            </p>
          </div>

          <Button onClick={handleRemoveCoBuyer}>Remove</Button>
        </div>
      </Modal>
    </div>
  );
};

export default ParaPartDataSection;
