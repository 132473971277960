import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentsIcon from '~/components/icons/DocumentsIcon';
import FileInput from '~/components/inputs/FileInput';
import { gqlMutationClient } from '~/lib/backend';
import meQuery from '~/queries/meQuery';
import { Link, useNavigate, useParams } from '~/router';
import { AdditionalDocumentMaps, DocumentType } from '..';
import ConsumerDashboardHeader from '../../_components/ConsumerDashboardHeader';

const AddAdditionalDocumentTypes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionId, documentType, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType'
  );
  const documentName = location.state?.documentName;
  const documentTypeKey = documentType as DocumentType;

  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = async () => {
    setLoggingOut(true);
    await logoutAction();
  };

  const { isLoading: loadingUserData, data: meData } = useQuery(meQuery());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleUpload = async () => {
    if (!selectedFiles.length) return;
    const fileToUpload = selectedFiles[0];

    setIsSubmitting(true);

    const fileExt = fileToUpload.name.split('.').pop() ?? 'unknown';
    const mimeType = fileToUpload.type || 'application/octet-stream';

    const pictureUpload = await gqlMutationClient()({
      makeUpload: [
        {
          mimeType: mimeType,
          fileExt: fileExt
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationMakeUploadSuccess': {
            data: {
              fileId: true,
              uploadUrl: true
            }
          }
        }
      ]
    });

    const optimisticResp =
      pictureUpload.makeUpload.__typename !== 'GraphQLError'
        ? pictureUpload.makeUpload.data
        : undefined;

    if (!optimisticResp) return;

    if (optimisticResp?.uploadUrl) {
      await fetch(optimisticResp.uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': mimeType,
          'Content-Length': fileToUpload.size.toString()
        },
        body: fileToUpload
      });
    }

    await gqlMutationClient()({
      uploadAdditionalDocuments: [
        {
          category: documentType,
          transactionId: transactionId,
          uploadedDocumentId: optimisticResp.fileId,
          title: documentName
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationUploadAdditionalDocumentsSuccess': {
            data: {
              id: true
            }
          }
        }
      ]
    });

    setIsSubmitting(false);
    navigate(`/dashboard/:dealershipSlug/:transactionId/gloveCompartment`, {
      params: { transactionId, dealershipSlug }
    });
  };

  return (
    <div className="flex flex-col h-dvh w-full">
      <ConsumerDashboardHeader
        centerElement={
          <div className="flex flex-row space-x-2">
            <div className="relative">
              <DocumentsIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <h2 className="hidden md:flex items-center">Documents & Forms</h2>

            <h3 className="flex md:hidden items-center">Documents & Forms</h3>
          </div>
        }
        rightElement={
          <Button
            variant="TEXT_ONLY"
            size="SMALL"
            onClick={handleLogout}
            loading={loggingOut}
            className="!p-0"
          >
            <span className="text-secondary">Logout</span>
          </Button>
        }
        showBorder
      />

      <div className="flex flex-col pt-4 space-y-6 w-full max-w-screen-md items-center self-center overflow-y-scroll">
        <div className="w-full px-6">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>

        {loadingUserData && (
          <div className="flex w-full max-w-screen-md aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}

        {!loadingUserData && (
          <div className="flex flex-col space-y-4 w-full">
            <div className="space-y-2 w-full px-6">
              <h2>
                Add {AdditionalDocumentMaps[documentTypeKey] ?? 'Additional'}{' '}
                Documents
              </h2>
              <p>
                Please upload documents or capture them using the device camera.
              </p>
            </div>

            <div className="flex flex-col w-full p-6">
              <div>
                <FileInput
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  uploading={isSubmitting}
                />
              </div>
              <div className="flex flex-row gap-4 mt-7">
                <Button
                  size="DEFAULT"
                  disabled={!(selectedFiles.length > 0) || isSubmitting}
                  onClick={handleUpload}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
                <Button
                  size="DEFAULT"
                  variant="DESTRUCTIVE"
                  onClick={() => {
                    setSelectedFiles([]);
                  }}
                  disabled={!(selectedFiles.length > 0) || isSubmitting}
                >
                  Clear File
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddAdditionalDocumentTypes;
