import { ValidationError } from '@tanstack/react-form';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { FC, useRef, useState } from 'react';
import CalendarIcon from '../icons/CalendarIcon';

const DateInput: FC<{
  /**
   * Date as a string value in ISO format.
   */
  value: string;
  fieldName: string;
  labelText: string;
  subtitleText?: string;
  error?: ValidationError;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  inputClassName?: string;
  containerClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  dataTestId?: string;
}> = ({
  value,
  fieldName,
  labelText,
  subtitleText,
  error,
  disabled,
  placeholder,
  required,
  inputClassName,
  containerClassName,
  onChange,
  dataTestId
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showDateInputPlaceholder, setShowDateInputPlaceholder] = useState(
    !value.length
  );
  const handleDateInputPlaceholderClick = () => {
    setShowDateInputPlaceholder(false);
    inputRef.current?.focus(); // This is required to open the date picker on iOS devices
  };

  return (
    <div className={classNames('flex flex-col space-y-2', containerClassName)}>
      <div className="relative flex flex-row border-b-[0.5px] z-0 text-body-1">
        <label htmlFor={fieldName} className="hidden">
          {labelText}
        </label>

        <input
          data-test-id={dataTestId}
          ref={inputRef}
          type="date"
          name={fieldName}
          className={classNames(
            'h-8 px-2 appearance-none outline-none bg-transparent placeholder:text-inactive disabled:disabled-input',
            {
              'w-full': !!value,
              'w-fit': !value
            },
            inputClassName
          )}
          onChange={onChange}
          value={value ? format(parseISO(value), 'yyyy-MM-dd') : ''}
          placeholder={placeholder}
          aria-placeholder={placeholder}
          disabled={disabled}
        />

        {/**
         * This is a temporary solution for showing a placeholder on native date pickers in iOS. We should create our own custom component or use something like react-datepicker.
         */}
        {showDateInputPlaceholder && (
          <p
            className={classNames(
              'flex items-center absolute top-0 bottom-0 left-2 right-0 z-10 cursor-pointer bg-primary',
              {
                'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
              }
            )}
            onClick={handleDateInputPlaceholderClick}
          >
            {placeholder}
          </p>
        )}

        <div className="absolute top-0 right-2 bottom-0">
          <CalendarIcon
            className={classNames('w-4 icon-tertiary', {
              'icon-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          />
        </div>
      </div>

      <div className="flex flex-row">
        {subtitleText && !error && (
          <p
            className={classNames('text-body-3 ml-2 text-tertiary', {
              'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          >
            {subtitleText}
          </p>
        )}

        {required && !error && (
          <p
            className={classNames('text-body-3 ml-2 text-tertiary italic', {
              'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          >
            Required
          </p>
        )}

        {error && <p className="text-body-3 ml-2 text-negative">{error}</p>}
      </div>
    </div>
  );
};

export default DateInput;
