import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import updateVehicleAction from '~/actions/vehicles/updateVehicleAction';
import Button from '~/components/Button';
import Header from '~/components/Header';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DropDown from '~/components/inputs/DropDown';
import TextInput from '~/components/inputs/TextInput';
import { gqlQueryClient } from '~/lib/backend';
import { queryClient } from '~/main';
import { resetMeQuery } from '~/queries/meQuery';
import { VehicleType, vehicleSelector } from '~/querySelectors/vehicle';
import { Link, useNavigate, useParams } from '~/router';

const PurchaseVehicleForm: FC<{
  vehicle?: VehicleType;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ vehicle, isSubmitting, setIsSubmitting }) => {
  const navigate = useNavigate();
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId'
  );
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const form = useForm({
    defaultValues: {
      vin: vehicle?.vin ?? '',
      stockNumber: vehicle?.stockNumber ?? '',
      make: vehicle?.make ?? '',
      model: vehicle?.model ?? '',
      year: vehicle?.year ?? '',
      trim: vehicle?.trim ?? '',
      isUsed:
        vehicle?.isUsed !== undefined ? (vehicle.isUsed ? 'USED' : 'NEW') : ''
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);

      await updateVehicleAction(transactionId, 'PURCHASE', vehicleId, {
        ...values,
        isUsed: values.isUsed === 'USED'
      });

      await Promise.all([
        await resetMeQuery(),
        await queryClient.resetQueries({
          queryKey: ['transaction', transactionId]
        })
      ]);

      navigate('/dashboard/:dealershipSlug/:transactionId', {
        params: { transactionId, dealershipSlug }
      });

      setIsSubmitting(false);
    }
  });

  return (
    <form.Provider>
      <form
        className="space-y-4 md:space-y-6"
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <form.Field name="vin">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText="VIN#"
                placeholder="VIN#"
                subtitleText="VIN#"
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        </form.Field>

        <form.Field name="stockNumber">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText="Stock Number"
                placeholder="Stock Number"
                subtitleText="Stock Number"
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        </form.Field>

        <form.Field name="make">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText="Make"
                placeholder="Make"
                subtitleText="Make"
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        </form.Field>

        <form.Field name="model">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText="Model"
                placeholder="Model"
                subtitleText="Model"
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        </form.Field>

        <form.Field name="year">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText="Year"
                placeholder="Year"
                subtitleText="Year"
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        </form.Field>

        <form.Field name="trim">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText="Trim"
                placeholder="Trim"
                subtitleText="Trim"
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        </form.Field>

        <form.Field name="isUsed">
          {(field) => {
            return (
              <DropDown
                fieldName={field.name}
                value={field.state.value}
                labelText="Condition"
                placeholder="Condition"
                subtitleText="Condition"
                options={[
                  { value: 'USED', label: 'used' },
                  { value: 'NEW', label: 'new' }
                ]}
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        </form.Field>

        <div className="flex flex-row justify-between pt-4">
          <Button
            variant="TERTIARY"
            type="button"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button type="submit" loading={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </form.Provider>
  );
};

const PurchaseVehiclePage = () => {
  const navigate = useNavigate();
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ['transaction', transactionId, 'vehicle', vehicleId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId
          },
          {
            vehicle: vehicleSelector
          }
        ]
      })
  });
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="Vehicle for Purchase"
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Cancel
          </button>
        }
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col items-center overflow-y-scroll px-6">
        <div className="flex flex-col max-w-screen-md py-6 md:py-10 space-y-8 md:space-y-16 w-full">
          <div className="space-y-5">
            <h2>Vehicle for Purchase</h2>

            <p>Please fill in or verify the information in the fields below.</p>
          </div>

          {isLoading && (
            <div className="flex w-full aspect-square justify-center items-center relative">
              <Spinner className="w-10 aspect-square" />
            </div>
          )}

          {!isLoading && (
            <PurchaseVehicleForm
              vehicle={data?.transaction.vehicle}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseVehiclePage;
