import classNames from 'classnames';
import { FC } from 'react';
import Checkbox from './inputs/Checkbox';

const SelectableRow: FC<{
  fieldId?: string;
  selected: boolean;
  text: string;
  // Loosened from MouseEventHandler<HTMLDivElement>
  onClick: () => void;
  indented?: boolean;
  disabled?: boolean;
}> = ({ fieldId, selected, text, onClick, indented, disabled }) => {
  return (
    <div
      className={classNames(
        'flex flex-row px-4 py-5 space-x-6 items-center border-b',
        {
          '': selected && !indented,
          'pl-16': indented,
          'cursor-default': disabled,
          'cursor-pointer': !disabled
        }
      )}
      onClick={disabled ? undefined : onClick}
      onChange={() => {}}
    >
      <Checkbox
        onChange={() => onClick()}
        inputId={fieldId}
        value={selected}
        disabled={disabled}
        variant="RADIO_BUTTON"
      />

      <div>{text}</div>
    </div>
  );
};

export default SelectableRow;
