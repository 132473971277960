// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/dashboard`
  | `/dashboard/:dealershipSlug/:transactionId`
  | `/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments`
  | `/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType`
  | `/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType/capture`
  | `/dashboard/:dealershipSlug/:transactionId/coBuyer`
  | `/dashboard/:dealershipSlug/:transactionId/compliance/:collection`
  | `/dashboard/:dealershipSlug/:transactionId/gloveCompartment`
  | `/dashboard/:dealershipSlug/:transactionId/gloveCompartment/:formSubmissionId`
  | `/dashboard/:dealershipSlug/:transactionId/gloveCompartment/document/:documentId`
  | `/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo`
  | `/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/basicInfo`
  | `/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo`
  | `/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationForm`
  | `/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationNotice`
  | `/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/personalReference`
  | `/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/submitApplication`
  | `/dashboard/:dealershipSlug/:transactionId/personalInformation`
  | `/dashboard/:dealershipSlug/:transactionId/preQual`
  | `/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId`
  | `/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information`
  | `/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff`
  | `/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/pictures`
  | `/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard`
  | `/onboarding`
  | `/onboarding/:dealershipSlug/:transactionId/step1`
  | `/onboarding/:dealershipSlug/:transactionId/step2`
  | `/prequal/:dealershipSlug`
  | `/signin`
  | `/signup`

export type Params = {
  '/dashboard/:dealershipSlug/:transactionId': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType': { dealershipSlug: string; transactionId: string; documentType: string }
  '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType/capture': { dealershipSlug: string; transactionId: string; documentType: string }
  '/dashboard/:dealershipSlug/:transactionId/coBuyer': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/compliance/:collection': { dealershipSlug: string; transactionId: string; collection: string }
  '/dashboard/:dealershipSlug/:transactionId/gloveCompartment': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/gloveCompartment/:formSubmissionId': { dealershipSlug: string; transactionId: string; formSubmissionId: string }
  '/dashboard/:dealershipSlug/:transactionId/gloveCompartment/document/:documentId': { dealershipSlug: string; transactionId: string; documentId: string }
  '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo': { dealershipSlug: string; transactionId: string; hardCreditApplicationId: string }
  '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/basicInfo': { dealershipSlug: string; transactionId: string; hardCreditApplicationId: string }
  '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo': { dealershipSlug: string; transactionId: string; hardCreditApplicationId: string }
  '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationForm': { dealershipSlug: string; transactionId: string; hardCreditApplicationId: string }
  '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationNotice': { dealershipSlug: string; transactionId: string; hardCreditApplicationId: string }
  '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/personalReference': { dealershipSlug: string; transactionId: string; hardCreditApplicationId: string }
  '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/submitApplication': { dealershipSlug: string; transactionId: string; hardCreditApplicationId: string }
  '/dashboard/:dealershipSlug/:transactionId/personalInformation': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/preQual': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/pictures': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/onboarding/:dealershipSlug/:transactionId/step1': { dealershipSlug: string; transactionId: string }
  '/onboarding/:dealershipSlug/:transactionId/step2': { dealershipSlug: string; transactionId: string }
  '/prequal/:dealershipSlug': { dealershipSlug: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
