import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';

type Fields =
  | 'dealershipName'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'suffix'
  | 'birthdate'
  | 'socialSecurityNumber'
  | 'street'
  | 'city'
  | 'state'
  | 'zip'
  | 'signature'
  // For legal stamps
  | 'name'
  | 'dateTime'
  | 'deviceId'
  | 'ipAddress';

export const prequalForm: DocumentTemplate<Fields> = {
  fileUrl: '/docs/prequal-application-template.pdf',
  fields: {
    ...legalStamps('form-prequal-form', [0, 1, 2]),
    dealershipName: {
      formFieldId: 'form-prequal-form-field-1',
      formId: 'form-prequal-form',
      demoData: 'Test Dealer',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-1-placement-1',
          positionX: 80,
          positionY: 510,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    firstName: {
      formFieldId: 'form-prequal-form-field-2',
      formId: 'form-prequal-form',
      demoData: 'FirstName',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-2-placement-1',
          positionX: 20,
          positionY: 116,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    middleName: {
      formFieldId: 'form-prequal-form-field-3',
      formId: 'form-prequal-form',
      demoData: 'MiddleName',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-3-placement-1',
          positionX: 310,
          positionY: 116,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    lastName: {
      formFieldId: 'form-prequal-form-field-4',
      formId: 'form-prequal-form',
      demoData: 'LastName',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-4-placement-1',
          positionX: 20,
          positionY: 166,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    suffix: {
      formFieldId: 'form-prequal-form-field-5',
      formId: 'form-prequal-form',
      demoData: 'jr.',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-5-placement-1',
          positionX: 310,
          positionY: 166,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    birthdate: {
      formFieldId: 'form-prequal-form-field-6',
      formId: 'form-prequal-form',
      demoData: '22/10/1985',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-6-placement-1',
          positionX: 20,
          positionY: 211,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    socialSecurityNumber: {
      formFieldId: 'form-prequal-form-field-7',
      formId: 'form-prequal-form',
      demoData: '123 456 7890',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-7-placement-1',
          positionX: 310,
          positionY: 211,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    street: {
      formFieldId: 'form-prequal-form-field-8',
      formId: 'form-prequal-form',
      demoData: 'Stree Ave 434',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-8-placement-1',
          positionX: 20,
          positionY: 261,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    city: {
      formFieldId: 'form-prequal-form-field-9',
      formId: 'form-prequal-form',
      demoData: 'New York',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-9-placement-1',
          positionX: 245,
          positionY: 261,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    state: {
      formFieldId: 'form-prequal-form-field-10',
      formId: 'form-prequal-form',
      demoData: 'New York',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-10-placement-1',
          positionX: 405,
          positionY: 261,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    zip: {
      formFieldId: 'form-prequal-form-field-11',
      formId: 'form-prequal-form',
      demoData: '234566',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-11-placement-1',
          positionX: 505,
          positionY: 261,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    signature: {
      formFieldId: 'form-prequal-form-field-12',
      formId: 'form-prequal-form',
      demoData: 'John Doe',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-prequal-form-field-12-placement-1',
          positionX: 150,
          positionY: 660,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'SIGNATURE',
      createdAt: '2024-05-21 00:00:00.000'
    }
  }
};
