import { GraphQLTypes } from '~/__generated__/backend/zeus';
import createAddressAction from '../addresses/createAddressAction';
import updateAddressAction from '../addresses/updateAddressAction';
import updateEmploymentAction from './updateEmploymentAction';

const updateEmploymentAndEmployerAction = async (
  transactionId: string,
  employmentId: string,
  values: GraphQLTypes['UpdateEmploymentInput'],
  employerAddress: GraphQLTypes['AddressInput']
) => {
  let employerAddressId: string | undefined = undefined;

  // Update or create the employer address if necessary
  if (['EMPLOYED', 'SELF_EMPLOYED'].includes(values.employmentStatus ?? '')) {
    if (values.employerAddressId) {
      employerAddressId = values.employerAddressId;

      await updateAddressAction(employerAddressId, employerAddress);
    } else {
      const address = await createAddressAction(employerAddress, transactionId);

      employerAddressId = address.id;
    }
  }

  await updateEmploymentAction(employmentId, { ...values, employerAddressId });
};

export default updateEmploymentAndEmployerAction;
