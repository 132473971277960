import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';

type Fields = 'image' | 'name' | 'dateTime' | 'deviceId' | 'ipAddress';
export const identityTheftPreventionForm: DocumentTemplate<Fields> = {
  fileUrl: '/docs/identity-theft-prevention.pdf',
  fields: {
    image: {
      formFieldId: 'form-identity-theft-prevention-field-1',
      formId: 'form-identity-theft-prevention',
      type: 'IMAGE',
      formFieldPlacements: [
        {
          formFieldPlacementId:
            'form-identity-theft-prevention-field-1-placement-1',
          positionX: 240,
          positionY: 400,
          page: 0,
          width: 133,
          height: 200,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      createdAt: '2024-05-21 00:00:00.000'
    },
    ...legalStamps('form-identity-theft-prevention', [0])
  }
};
