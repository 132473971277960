import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';
import { employmentSelector } from '~/querySelectors/employment';

const updateEmploymentAction = async (
  employmentId: string,
  values: GraphQLTypes['UpdateEmploymentInput']
) => {
  const resp = await gqlMutationClient()({
    updateEmployment: [
      {
        id: employmentId,
        employment: values
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationUpdateEmploymentSuccess': {
          data: employmentSelector
        }
      }
    ]
  });

  if (resp.updateEmployment.__typename === 'GraphQLError') {
    throw new Error(resp.updateEmployment.message);
  }
};

export default updateEmploymentAction;
