import { gqlMutationClient } from '~/lib/backend';

const requestOtpExistingUserDEVAction = async (email: string) => {
  const resp = await gqlMutationClient()({
    requestOtpExistingUserDEV: [
      {
        email
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationRequestOtpExistingUserDEVSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (resp.requestOtpExistingUserDEV.__typename === 'GraphQLError') {
    throw new Error(resp.requestOtpExistingUserDEV.message);
  }

  return resp.requestOtpExistingUserDEV.data;
};

export default requestOtpExistingUserDEVAction;
