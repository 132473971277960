import { Dialog, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormSubmissionType } from '~/__generated__/backend/zeus';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import CircleCheckMarkIcon from '~/components/icons/CircleCheckMarkIcon';
import config from '~/config';
import {
  getAuthHeaders,
  gqlMutationClient,
  gqlQueryClient
} from '~/lib/backend';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';
import PdfFiller, {
  formFieldSelector,
  mkFormDataAtom
} from './_components/PdfFilling';

export default function PrePurchasePage() {
  const navigate = useNavigate();
  const { transactionId, collection, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/compliance/:collection'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: meData } = useQuery(meQuery());
  const [error, setError] = useState<null | string>(null);

  const userId = meData?.me.user.id;

  const {
    data: ppSubmission,
    isLoading,
    refetch
  } = useQuery({
    enabled: Boolean(userId),
    queryKey: ['documentSigning', collection, transactionId, dealershipSlug],
    queryFn: () =>
      gqlQueryClient({ dealershipSlug })({
        transaction: [
          {
            id: transactionId
          },
          {
            formSubmissions: [
              {
                // TODO: Add query order and sort on the backend by order position and then only fetch one
                filter: {
                  type: {
                    equals:
                      collection === 'prepurchase'
                        ? FormSubmissionType.PRE_PURCHASE
                        : FormSubmissionType.POST_PURCHASE
                  }
                },
                hasMissingValuesBy: userId ?? 'never'
              },
              {
                totalCount: true,
                edges: {
                  node: {
                    // Submission ID
                    id: true,
                    formFieldSubmissions: [
                      {
                        filter: {
                          signerId: {
                            equals: userId
                          }
                        }
                      },
                      {
                        edges: {
                          node: {
                            field: formFieldSelector
                          }
                        }
                      }
                    ],
                    form: {
                      formOrder: {
                        position: true
                      }
                    }
                  }
                }
              }
            ]
          }
        ]
      })
  });

  const [formDataAtom] = useState(mkFormDataAtom());
  const formData = useAtomValue(formDataAtom);

  const firstSubmission = ppSubmission?.transaction.formSubmissions.edges
    // Sort the submission by form order
    .sort((a, b) => {
      if (
        typeof a.node.form?.formOrder?.position !== 'number' ||
        typeof b.node.form?.formOrder?.position !== 'number'
      ) {
        return 0;
      }

      return a.node.form.formOrder.position - b.node.form.formOrder.position;
    })
    .at(0);

  const hasForm = firstSubmission && !isLoading;
  const submissionId = firstSubmission?.node.id;

  // Bah, TODO: Rewrite this with something that does not use effects and state.
  const [authHeaders, setAuthHeaders] = useState<HeadersInit>({});
  useEffect(() => {
    getAuthHeaders().then(setAuthHeaders);
  }, []);

  const allSubmitted =
    Object.values(formData).filter(
      (e) =>
        e.t === 'string'
          ? e.value.length > 0
          : e.t === 'file'
            ? !!e.fileId
            : e.t === 'checkbox'
              ? true // Checkboxes are always done
              : false // Never happens
    ).length ===
    (firstSubmission?.node.formFieldSubmissions.edges.length ?? -1);

  const onNext = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const values = Object.keys(formData).map((formFieldId) => {
        const v = formData[formFieldId];

        return {
          submissionFieldId: formFieldId,
          value:
            v.t === 'string' || v.t === 'checkbox' ? `${v.value}` : undefined,
          // For signatures
          fileId: v.t === 'file' ? v.fileId : undefined
        };
      });

      if (!submissionId) {
        throw new Error('No submission ID');
      }

      const resp = await gqlMutationClient()({
        submitFormValues: [
          {
            formSubmissionId: submissionId,
            values: values
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationSubmitFormValuesSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (
        resp.submitFormValues.__typename !== 'MutationSubmitFormValuesSuccess'
      ) {
        setError(resp.submitFormValues.message);

        return;
      }

      // Reset potential error
      setError(null);

      const refetchValue = await refetch();
      const refetchedSubmission =
        // There's no need to sort the form submissions here because we will reload the window when there is one left.
        // Upon reload, we are sorting the remaining form submissions and pick the one that's supposed to come next.
        refetchValue.data?.transaction.formSubmissions.edges.at(0);

      // If there is no more submissions, then we redirect in 3 sec
      if (!refetchedSubmission) {
        setTimeout(
          () =>
            navigate('/dashboard/:dealershipSlug/:transactionId', {
              params: {
                dealershipSlug,
                transactionId
              }
            }),
          3_000
        );
      } else {
        // Because there is so much non-standard state loading here, this
        // seems to be the way to ensure that the state is alright for the
        // next form.
        window.location.reload();
      }
    } catch (e) {
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  }, [formData, submissionId, refetch]);

  const content = useMemo(
    () => (
      <>
        {submissionId && (
          <PdfFiller
            pdfUrl={`${config.rawBackendUrl}/compliance/pdf/${submissionId}`}
            authHeaders={authHeaders}
            formDataAtom={formDataAtom}
            formFieldSubmissions={firstSubmission.node.formFieldSubmissions.edges.map(
              (e) => e.node.field
            )}
          />
        )}
      </>
    ),
    [
      authHeaders,
      submissionId,
      firstSubmission?.node.formFieldSubmissions.edges
    ]
  );

  return (
    <>
      {isLoading && (
        <div className="flex w-full aspect-square justify-center items-center relative">
          <Spinner className="w-10 aspect-square" />
        </div>
      )}
      {!isLoading && (
        <div className="flex justify-center">
          <div className="flex flex-col w-full max-w-screen-md space-y-6 max-h-screen">
            {/* Success Modal */}
            <Transition appear show={!hasForm} as={Fragment}>
              {/* Can not close */}
              <Dialog as="div" className="relative z-30" onClose={() => null}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel
                        className={
                          'w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6'
                        }
                      >
                        <div className="py-4 text-center text-heading-3">
                          <Dialog.Title className="">
                            Documents Were Signed Successfully!
                          </Dialog.Title>
                        </div>

                        <p className="flex justify-center">
                          <CircleCheckMarkIcon className="text-[#219653] w-8 h-8" />
                        </p>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

            {/* Submission Loading Modal */}
            <Transition appear show={isSubmitting} as={Fragment}>
              {/* Can not close */}
              <Dialog as="div" className="relative z-30" onClose={() => null}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel
                        className={
                          'w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6'
                        }
                      >
                        <div className="py-4 text-center text-heading-3">
                          <Dialog.Title className="">
                            Submitting Form
                          </Dialog.Title>
                        </div>

                        <Spinner className="w-6 aspect-square mx-auto my-4" />
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

            {/* Form content */}

            {error && (
              <p className="text-body-3 ml-2 rounded-lg py-4 px-6 font-bold text-white bg-negative-primary">
                {error}
              </p>
            )}

            {hasForm && (
              <div className="relative flex flex-1 py-6 justify-center overflow-y-scroll">
                {content}
              </div>
            )}

            {hasForm && (
              <div className="flex w-full justify-between px-4 pb-6">
                <Button variant="SECONDARY" onClick={() => navigate(-1)}>
                  Cancel
                </Button>

                <Button
                  variant="PRIMARY"
                  onClick={onNext}
                  disabled={!allSubmitted}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
