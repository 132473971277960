import { FormSubmissionType } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';

const createFormSubmissionAction = async (
  transactionId: string,
  type: FormSubmissionType,
  fileId: string,
  formSubmissionData: string
) => {
  const formSubmissionResp = await gqlMutationClient()({
    submitStandardForm: [
      {
        transactionId,
        type,
        signedUploadedDocumentId: fileId,
        formSubmissionData
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationSubmitStandardFormSuccess': {
          data: {
            id: true
          }
        }
      }
    ]
  });

  if (formSubmissionResp.submitStandardForm.__typename === 'GraphQLError') {
    throw new Error(formSubmissionResp.submitStandardForm.message);
  }

  return formSubmissionResp.submitStandardForm.data;
};

export default createFormSubmissionAction;
