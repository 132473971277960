import Image from 'image-js';

export const base64ToImageBlob = (base64String: string, mimeTyp: string) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: mimeTyp });
};

export const getHumanReadableFormAndDocumentType = (roleEnum: string) => {
  const formTypeMap: { [key: string]: string } = {
    PRIVACY_POLICY: 'Privacy Policy',
    CONTACT_ACKNOWLEDGEMENT: 'Contact Acknowledgement',
    IDENTITY_THEFT_PREVENTION: 'Identity Theft Prevention',
    IDENTITY_VERIFICATION: 'Identity Verification',
    PREQUAL: 'Prequal',
    HARD_CREDIT_APPLICATION: 'Credit Application',
    HARD_CREDIT_APPLICATION_MODIFICATION: 'Credit Application Modification',
    TEST_DRIVE_DISCLOSURE: 'Test Drive Disclosure',
    UTILITY_BILLS: 'Utility Bills',
    PROOF_OF_INCOME: 'Proof of Income',
    BANK_STATEMENTS: 'Bank Statements',
    DMV_DOCUMENTS: 'DMV Documents',
    OTHER: 'Other'
  };

  return formTypeMap[roleEnum] ?? roleEnum;
};

export const cropCardHolderImage = async (file: File) => {
  const image = (await Image.load(URL.createObjectURL(file))).rotateRight(); // When we load the image with image-js, it has it in landscape mode, so we have to rotate it
  const width = image.width;
  const height = image.height;
  const croppedImageBlob = await image
    .crop({
      // X and Y are in the top left of the image
      x: 0, // The card should have no offset on the left
      y: height * 0.06, // The card holder has a little offset at the top
      width: width * 0.87,
      height: height * 0.92
    })
    .rotateRight()
    .toBlob('image/jpeg');

  return new File([croppedImageBlob], 'cropped_image.jpeg', {
    type: 'image/jpeg'
  });
};
