import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';
import { AddressType, addressSelector } from '~/querySelectors/address';

const updateAddressAction = async (
  addressId: string,
  values: GraphQLTypes['AddressInput']
): Promise<AddressType> => {
  const resp = await gqlMutationClient()({
    updateAddress: [
      {
        id: addressId,
        address: values
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationUpdateAddressSuccess': {
          data: addressSelector
        }
      }
    ]
  });

  if (resp.updateAddress.__typename === 'GraphQLError') {
    throw new Error(resp.updateAddress.message);
  }

  return resp.updateAddress.data;
};

export default updateAddressAction;
