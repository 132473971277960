import { EmploymentStatus } from '@thedealersconcierge/lib/codecs/tdc';
import { GraphQLTypes } from '~/__generated__/backend/zeus';
import createAddressAction from '../addresses/createAddressAction';
import createEmploymentAction from './createEmploymentAction';

const createEmploymentAndEmployerAction = async (
  transactionId: string,
  values: GraphQLTypes['CreateEmploymentInput'],
  employerAddress: GraphQLTypes['AddressInput']
) => {
  let employerAddressId: string | undefined = undefined;
  const employmentStatus = EmploymentStatus.parse(values.employmentStatus);

  // Create the employer address if necessary
  if (employmentStatus === 'EMPLOYED' || employmentStatus === 'SELF_EMPLOYED') {
    const address = await createAddressAction(employerAddress, transactionId);

    employerAddressId = address.id;
  }

  await createEmploymentAction(transactionId, { ...values, employerAddressId });
};

export default createEmploymentAndEmployerAction;
