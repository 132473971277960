import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { FC, Fragment, useState } from 'react';
import createAddressAction from '~/actions/addresses/createAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import Button from '~/components/Button';
import Header from '~/components/Header';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DropDown from '~/components/inputs/DropDown';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import TextInput from '~/components/inputs/TextInput';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { gqlMutationClient } from '~/lib/backend';
import {
  mkHardCreditQuery,
  resetHardCreditQuery
} from '~/queries/hardCreditQuery';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import { Link, useNavigate, useParams } from '~/router';
import { hardCreditApplicationQuery } from './_query';

const HardCreditApplicationPersonalReferenceForm: FC<{
  hardCreditApplication?: HardCreditApplicationType;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ hardCreditApplication, isSubmitting, setIsSubmitting }) => {
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/personalReference'
  );
  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    let addressId: string | undefined = undefined;

    // Check if all required address data is available
    if (
      values.personalReferenceAddressStreet &&
      values.personalReferenceAddressZipCode &&
      values.personalReferenceAddressCity &&
      values.personalReferenceAddressState
    ) {
      // Check if personal reference address already exists
      if (!hardCreditApplication?.personalReferenceAddress) {
        const createdAddress = await createAddressAction({
          street: values.personalReferenceAddressStreet,
          apartmentDetails: values.personalReferenceAddressApartmentDetails,
          zipCode: values.personalReferenceAddressZipCode,
          city: values.personalReferenceAddressCity,
          state: values.personalReferenceAddressState
        });

        addressId = createdAddress.id;
      } else {
        // The address object already exists and we update it
        addressId = hardCreditApplication.personalReferenceAddress.id;

        await updateAddressAction(addressId, {
          street: values.personalReferenceAddressStreet,
          apartmentDetails: values.personalReferenceAddressApartmentDetails,
          zipCode: values.personalReferenceAddressZipCode,
          city: values.personalReferenceAddressCity,
          state: values.personalReferenceAddressState
        });
      }
    }

    const resp = await gqlMutationClient()({
      updateHardCreditApplication: [
        {
          id: hardCreditApplication?.id ?? 'no-hard-credit-application-id',
          hardCreditApplication: {
            personalReferenceFirstName: values.personalReferenceFirstName,
            personalReferenceMiddleName: values.personalReferenceMiddleName,
            personalReferenceLastName: values.personalReferenceLastName,
            personalReferenceRelationship: values.personalReferenceRelationship,
            personalReferencePhoneNumber: values.personalReferencePhoneNumber // We have to check whether the phone number has been set becausean empty string will fail the phone number validation
              .length
              ? values.personalReferencePhoneNumber
              : undefined,
            personalReferenceAddressId: addressId
          }
        },
        {
          __typename: true,
          '...on GraphQLError': {
            message: true
          },
          '...on MutationUpdateHardCreditApplicationSuccess': {
            data: {
              status: true
            }
          }
        }
      ]
    });

    if (resp.updateHardCreditApplication.__typename === 'GraphQLError') {
      console.error(resp.updateHardCreditApplication.message);

      window.alert(
        'Could not save the personal reference. Please try again or contact support.'
      );
    } else {
      await Promise.all([
        await resetMeQuery(),
        await resetHardCreditQuery(transactionId)
      ]);

      navigate(
        '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/submitApplication',
        {
          params: {
            dealershipSlug,
            transactionId,
            hardCreditApplicationId
          }
        }
      );
    }

    setIsSubmitting(false);
  };

  const form = useForm({
    defaultValues: {
      personalReferenceFirstName:
        hardCreditApplication?.personalReferenceFirstName ?? '',
      personalReferenceMiddleName:
        hardCreditApplication?.personalReferenceMiddleName ?? '',
      personalReferenceLastName:
        hardCreditApplication?.personalReferenceLastName ?? '',
      personalReferenceRelationship:
        hardCreditApplication?.personalReferenceRelationship ?? '',
      personalReferencePhoneNumber:
        hardCreditApplication?.personalReferencePhoneNumber ?? '',
      personalReferenceAddressStreet:
        hardCreditApplication?.personalReferenceAddress?.street ?? '',
      personalReferenceAddressApartmentDetails:
        hardCreditApplication?.personalReferenceAddress?.apartmentDetails ?? '',
      personalReferenceAddressZipCode:
        hardCreditApplication?.personalReferenceAddress?.zipCode ?? '',
      personalReferenceAddressCity:
        hardCreditApplication?.personalReferenceAddress?.city ?? '',
      personalReferenceAddressState:
        hardCreditApplication?.personalReferenceAddress?.state ?? ''
    },
    onSubmit: handleSubmit
  });

  return (
    <form.Provider>
      <Fragment>
        <form
          className="flex flex-col space-y-6 w-full bg-secondary p-6 items-center border-y border-secondary"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
        >
          <div className="flex flex-col items-start w-full max-w-screen-md space-y-6">
            <h2>Personal Reference</h2>

            <div className="grid grid-cols-2 md:grid-cols-8 gap-4 items-end w-full">
              <form.Field name="personalReferenceFirstName">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="First Name"
                      placeholder="First Name"
                      subtitleText="First Name"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-3"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferenceMiddleName">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Middle Name"
                      subtitleText="Middle Name"
                      placeholder="Middle Name"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-2"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferenceLastName">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Last Name"
                      placeholder="Last Name"
                      subtitleText="Last Name"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-3"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferenceRelationship">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Relationship"
                      subtitleText="Relationship"
                      placeholder="Relationship"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferencePhoneNumber">
                {(field) => {
                  return (
                    <PhoneNumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      subtitleText="Phone Number"
                      placeholder="Phone Number"
                      onChange={(value) =>
                        field.handleChange(value?.toString() ?? '')
                      }
                      onBlur={field.handleBlur}
                      disabled={isSubmitting}
                      containerClassName="col-span-2 md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferenceAddressStreet">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Street"
                      subtitleText="Street"
                      placeholder="Street"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="col-span-2 md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferenceAddressApartmentDetails">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Apartment Details"
                      subtitleText="Apartment Details"
                      placeholder="Suite, Apt #"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferenceAddressZipCode">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Zip Code"
                      subtitleText="Zip Code"
                      placeholder="Zip Code"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-3"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferenceAddressCity">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="City"
                      subtitleText="City"
                      placeholder="City"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-2"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="personalReferenceAddressState">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={stateOptions}
                      labelText="State"
                      placeholder="State"
                      subtitleText="State"
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-3"
                    />
                  );
                }}
              </form.Field>
            </div>
          </div>
        </form>

        <div className="flex w-full px-6 justify-center">
          <div className="flex flex-row justify-end w-full max-w-screen-md">
            <form.Subscribe
              selector={(state) => [state.values]}
              children={([values]) => {
                return (
                  <Button
                    onClick={() => handleSubmit(values)}
                    loading={isSubmitting}
                  >
                    Next
                  </Button>
                );
              }}
            />
          </div>
        </div>
      </Fragment>
    </form.Provider>
  );
};

const HardCreditApplicationPersonalReferencePage = () => {
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/personalReference'
  );
  const { data: meData } = useQuery(meQuery());
  const { data, isFetched } = useQuery(mkHardCreditQuery(transactionId));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: hca } = useQuery(
    hardCreditApplicationQuery(transactionId, hardCreditApplicationId)
  );
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const isCoBuyer = meData?.me.user.id === data?.transaction.coBuyerId;
  const hardCreditApplication = isCoBuyer
    ? hca?.transaction.coBuyer?.hardCreditApplications.edges.at(0)?.node
    : hca?.transaction.buyer?.hardCreditApplications.edges.at(0)?.node;

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="Hard Credit Application"
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Cancel
          </button>
        }
        totalSteps={5}
        currentStep={4}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo"
            params={{ transactionId, hardCreditApplicationId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col py-10 space-y-6 w-full items-center overflow-y-scroll h-full">
        {isFetched ? (
          <Fragment>
            <div className="flex w-full px-6 justify-center">
              <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
                <h2>Credit Application</h2>

                <p>Please fill up the information below.</p>
              </div>
            </div>

            <HardCreditApplicationPersonalReferenceForm
              hardCreditApplication={hardCreditApplication}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          </Fragment>
        ) : (
          <div className="flex w-full aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HardCreditApplicationPersonalReferencePage;
