import { useQuery } from '@tanstack/react-query';
import parsePhoneNumber from 'libphonenumber-js';
import { useState } from 'react';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import { gqlMutationClient } from '~/lib/backend';
import dealershipQuery from '~/queries/dealershipQuery';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import transactionQuery, {
  resetTransactionQuery
} from '~/queries/transactionQuery';
import { useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from './_components/ConsumerDashboardHeader';
import PersonalInformationSection from './_components/PersonalInformationSection';
import TransactionDataSection from './_components/TransactionDataSection';

const DashboardPage = () => {
  const navigate = useNavigate();

  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId'
  );
  const [loggingOut, setLoggingOut] = useState(false);
  const [creatingVehicle, setCreatingVehicle] = useState(false);
  const dealership = useQuery(dealershipQuery({ slug: dealershipSlug }));

  const { data: transactionQueryData, isLoading } = useQuery(
    transactionQuery(transactionId, dealershipSlug)
  );
  const [selectedDealershipId] = useState<string | undefined>(undefined);
  const { data } = useQuery(meQuery());
  const dealershipId = dealership.data?.dealership.id;
  const dealerships = data?.me.dealerships;
  const profileData = data?.me.user;

  const selectedDealership = dealerships?.find((d) => {
    return (
      d.id === selectedDealershipId ||
      (!selectedDealershipId && dealershipId && d.id === dealershipId)
    );
  });

  const transaction = transactionQueryData?.transaction;
  // const buyer = transactionQueryData?.transaction.buyer;
  // const coBuyer = transactionQueryData?.transaction.coBuyer;
  const isCoBuyer =
    !!data?.me.user.id && transaction?.coBuyerId === data?.me.user.id;

  const vehicle = transactionQueryData?.transaction.vehicle;
  const tradeVehicle = transactionQueryData?.transaction.tradeVehicle;
  const handleGoToPersonalInformation = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId/personalInformation', {
      params: { transactionId, dealershipSlug }
    });
  };

  const handleGoToPurchaseVehicle = async () => {
    let vehicleId = vehicle?.id;

    if (vehicle) {
      navigate(
        '/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId',
        {
          params: {
            transactionId,
            vehicleId: vehicle.id,
            dealershipSlug
          }
        }
      );
    }

    setCreatingVehicle(true);

    const vehicleResp = await gqlMutationClient({ dealershipSlug })({
      createVehicle: [
        {
          transactionId,
          vehicleType: 'PURCHASE'
        },
        {
          __typename: true,
          '...on GraphQLError': {
            message: true
          },
          '...on MutationCreateVehicleSuccess': {
            data: {
              status: true,
              id: true
            }
          }
        }
      ]
    });

    if (
      vehicleResp.createVehicle.__typename !== 'MutationCreateVehicleSuccess'
    ) {
      console.error(vehicleResp.createVehicle.message);
      window.alert(
        'Could not add vehicle. Please try again or contact support.'
      );
      setCreatingVehicle(false);
      return;
    }

    await Promise.all([
      await resetMeQuery(),
      await resetTransactionQuery(transactionId, dealershipSlug)
    ]);

    setCreatingVehicle(false);

    navigate(
      '/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId',
      {
        params: {
          transactionId,
          vehicleId: vehicleResp.createVehicle.data.id ?? 'never',
          dealershipSlug
        }
      }
    );
  };

  const handleLogout = async () => {
    setLoggingOut(true);
    await logoutAction();
  };

  return (
    <div>
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={<h3>{selectedDealership?.name ?? 'Dealership'}</h3>}
        rightElement={
          <Button
            variant="TEXT_ONLY"
            onClick={handleLogout}
            loading={loggingOut}
            className="!py-0 pr-0 h-fit"
          >
            <span className="text-secondary">Logout</span>
          </Button>
        }
      />

      {transaction && (
        <PersonalInformationSection
          transactionRole={isCoBuyer ? 'CO_BUYER' : 'BUYER'}
          transaction={transaction}
          isBuyer={!isCoBuyer}
          name={
            (!isCoBuyer
              ? transactionQueryData?.transaction?.customerSharedData?.buyerName
              : transactionQueryData?.transaction?.customerSharedData
                  ?.coBuyerName) ?? '-'
          }
          phoneNumber={
            profileData?.phoneNumber
              ? (parsePhoneNumber(profileData.phoneNumber)?.format(
                  parsePhoneNumber(profileData.phoneNumber)?.country === 'US'
                    ? 'NATIONAL'
                    : 'INTERNATIONAL'
                ) ?? '-')
              : '-'
          }
          email={profileData?.email ?? '-'}
          onEditPersonalInformation={handleGoToPersonalInformation}
        />
      )}

      {isLoading || !transaction ? (
        <div className="flex justify-center pt-10">
          <Spinner />
        </div>
      ) : (
        <TransactionDataSection
          transaction={transaction}
          transactionRole={isCoBuyer ? 'CO_BUYER' : 'BUYER'}
          selectedTransactionId={transactionId}
          tradeVehicle={tradeVehicle}
          purchaseVehicle={vehicle}
          onEditPurchaseVehicle={handleGoToPurchaseVehicle}
          onAddPurchaseVehicle={handleGoToPurchaseVehicle}
          creatingPurchaseVehicle={creatingVehicle}
        />
      )}
    </div>
  );
};

export default DashboardPage;
